import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { insertCampaignDetails, getPreviousCards, insertProgressDetails } from '../../../../store/actions/Campaign/createCampaignActions';
import { withStyles } from '@material-ui/core/styles';
import { FORM_INPUT_STYLES, PRIMARY_COLOR, WHITE_BG, CAMPAIGN_FORM_STEPS_HEADING, FORM_GROUP, FORM_INPUT_CONTAINER_STYLES, CAMPAIGN_FORM_STEPS_LABEL, FIELD_INCORRECT_DATA_VALIDATION, INVALID_FORM_VALIDATION } from '../../../../utils/CssConstants';
import Validate from "../../../../utils/Validation";
import DatePicker from "react-datepicker";
import ExcelIcon from '../../../../assets/images/excel_icon.png';
import ImageUploader from '../ImageUploader/ImageUploader';
import Button from '../../../Custom/Button';
import moment, { now } from 'moment-timezone';
import Slider from '@material-ui/core/Slider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SampleCSV from '../../../../assets/sample.csv';
import GetCardsByBrandAPI from '../../../../services/Payment/GetCardsByBrandAPI';
import PostAddressValidateAPI from '../../../../services/Campaign/CreateCampaign/PostAddressValidateAPI';
import GetHandleAndHashtagAPI from '../../../../services/Campaign/CreateCampaign/GetHandleAndHashtagAPI';
import PostCSVValidateAPI from '../../../../services/Campaign/CreateCampaign/PostCSVValidateAPI';
import GetStatesAPI from '../../../../services/Common/GetStatesAPI';
import { getCountryStates } from '../../../../store/actions/Common/commonActions';
import CSVStatusModal from './CSVStatusModal/CSVStatusModal';
import CircularProgress from '@material-ui/core/CircularProgress';
import GetPlaceDetailsAPI from '../../../../services/Campaign/CreateCampaign/GetPlaceDetailsAPI';
import Helpers from '../../../../utils/Helpers'
import MapDropdown from '../../../Custom/MapDropdown';
import loadImage from 'blueimp-load-image';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';


const styles = theme => ({
    campaignFormTop: {
        display: 'flex',
        flexDirection: 'row',
        '@media (max-width: 1400px)': {
            flexDirection: 'column'
        }
    },
    campaignFormRight: {
        width: '60%',
        display: 'flex',
        flexDirection: 'column',
        '@media (max-width: 1400px)': {
            width: '100%',
        }
    },
    formGroup: FORM_GROUP,
    formInputContStyles: FORM_INPUT_CONTAINER_STYLES,
    formInputStyles: FORM_INPUT_STYLES,
    formHeadingStyles: CAMPAIGN_FORM_STEPS_HEADING,
    formInputLabels: CAMPAIGN_FORM_STEPS_LABEL,
    invalidFormInputs: INVALID_FORM_VALIDATION,
    fieldError: FIELD_INCORRECT_DATA_VALIDATION,
    labelResponsive: {
        [theme.breakpoints.down('sm')]: {
            width: '35%'
        }
    },
    spendingContainer: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    priceInputs: {
        padding: 10,
        width: '70%',
        border: '1px solid #CECECE',
        boxSizing: 'border-box'
    },
    limitPosting: {
        padding: 10,
        width: '50%',
        border: '1px solid #CECECE',
        boxSizing: 'border-box'
    },
    formDates: {
        width: '100%',
        display: 'flex',
    },
    datePickerPlaceholder: {
        width: '31%',
        '&::placeholder': {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: 20,
            color: '#000000',
        }
    },
    campaignFormLeft: {
        width: '40%',
        display: 'flex',
        flexDirection: 'column',
        padding: '30px 0px 0px 40px',
        justifyContent: 'space-between',
        '@media (max-width: 1400px)': {
            width: '100%',
            paddingLeft: 0
        }
    },
    createCampaignBottom: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    inputSide: {
        display: 'flex',
        width: '65%',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    amountPayoutContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    amountPayout: {
        width: '282px',
        height: '280px',
        borderRadius: '50%',
        border: '10px solid #1549d6',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 10
    },
    amountText: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 20,
    },
    amount: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: 30,
        color: "#1549d6",
        maxWidth: 235,
        wordBreak: 'break-word',
        textAlign: 'center'
    },
    formSubmit: {
        background: PRIMARY_COLOR,
        color: WHITE_BG,
        border: 'none',
        borderRadius: 10,
        height: 60,
        width: '50%',
        marginBottom: 35,
        outline: 'none',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 18
    },
    storesCont: {
        display: 'flex'
    },
    storeActiveButton: {
        background: 'linear-gradient(91.76deg, #37B1C8 4.51%, #1549d6 100%)',
        border: '1px solid #B7B7B7',
        boxSizing: 'borderBox',
        borderRadius: 20,
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 20,
        color: WHITE_BG,
        width: '43%',
        height: 90,
        marginRight: 20,
        outline: 'none'
    },
    storeButton: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 20,
        background: WHITE_BG,
        border: '1px solid #B7B7B7',
        boxSizing: 'borderBox',
        borderRadius: 20,
        width: '43%',
        height: 90,
        marginRight: 20,
        outline: 'none'
    },
    csvUploadingCont: {
        paddingTop: 50,
        display: 'flex',
        alignItems: 'center',
        marginBottom: 10
    },
    uploadStoreCont: {
        background: PRIMARY_COLOR,
        borderRadius: 10,
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 20,
        width: '45%',
        height: 75,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: WHITE_BG,
        marginBottom: 10
    },
    csvUploadButton: {
        opacity: 0,
        zIndex: 999,
        position: 'absolute',
        width: '20%',
        height: 'auto',
        "&::-webkit-file-upload-button": {
            visibility: 'hidden',
        },
        "&::before": {
            border: 'none',
        },
        "& > input": {
            display: 'none',
        },
        "&:hover::before": {
            border: '1px solid black'
        }
    },
    csvToolTip: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 14,
    },
    downloadCSV: {
        color: PRIMARY_COLOR,
        cursor: 'pointer'
    },
    fileInfo: {
        marginLeft: 20,
        display: 'flex',
        width: '55%',
    },
    fileName: {
        margin: '0px 10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 300,
        fontSize: 18,
    },
    longLatInputs: {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    cardCheck: {
        width: '10%',
        borderLeft: '1px solid #B7B7B7',
        borderRight: 'none',
        justifyContent: 'center',
        alignItems: 'center',
        paddingRight: 0
    },
    circleIcon: {
        position: 'absolute',
        top: 35,
        left: -10
    },
    fewAddressCont: {
        borderBottom: '1px solid #C7C7C7',
        paddingBottom: 20,
        "&:last-child": {
            borderBottom: 'none'
        }
    },
    imageResponsive: {
        display: 'none',
        '@media (max-width: 1400px)': {
            display: 'block',
        }
    },
    imageRightCont: {
        display: 'block',
        '@media (max-width: 1400px)': {
            display: 'none',
        }
    },
    perPostSlider: {
        width: '50%',
        margin: '0 20px',
        color: '#1549d6',
        '@media (max-width: 1400px)': {
            width: '30%',
        }
    },
    noOfPostsSlider: {
        width: '50%',
        margin: '0 20px',
        color: '#1549d6',
        '@media (max-width: 1400px)': {
            width: '30%',
        }
    },
    priceInputResponsive: {
        width: '100%'
    },
    limitPostingResponsive: {
        width: '70%',
        [theme.breakpoints.down('sm')]: {
            width: '100% !important'
        }
    },
    minFollowersCont: {
        // width: '75%',
        maxWidth: 'fit-content',
        [theme.breakpoints.down('sm')]: {
            width: '100% !important'
        }
    },
    postDurationCont: {
        // width: '75%',
        maxWidth: 'fit-content',
        [theme.breakpoints.down('sm')]: {
            width: '100% !important'
        }
    },
    labelSocialResponsive: {
        width: '7%',
        [theme.breakpoints.down('sm')]: {
            width: '10%'
        }
    },
    labelMinFollowers: {
        width: '80%',
        [theme.breakpoints.down('sm')]: {
            width: '80%',
            fontSize: 16,
        }
    },
    labelPostDuration: {
        width: '80%',
        [theme.breakpoints.down('sm')]: {
            width: '80%',
            fontSize: 16,
        },
        "&:last-child": {
            borderLeft: '1px solid #B7B7B7',
            paddingLeft: 15
        }
    },
    zipCodeResponsive: {
        width: '47.5%',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    limitPostingSlider: {
        width: '30%',
        margin: '0 20px',
        color: '#1549d6',
        '@media (max-width: 1400px)': {
            width: '30%',
        }
    },
    btnLoading: {
        color: "#FFF !important"
    },
    selectLabel: {
        fontFamily: 'Poppins',
        fontWeight: 600,
        fontSize: '13px',
        marginBottom: '10px',
        display: 'inline-block'
    },
    rememeberCheckbox: {
        height: 15,
        width: 15,
        marginRight: 10
    },
    termsOfServiceContainer: {
        color: '#000000',
        width: '100%',
        height: 'auto',
        display: 'flex',
        padding: 10,
        fontSize: 18,
        fontStyle: 'normal',
        fontFamily: 'Poppins',
        fontWeight: 'normal',
        borderRadius: 15,
        flexDirection: 'row',
        alignItems: 'baseline',
    },
});

const PrettoSlider = withStyles({
    root: {
        color: '#37B1C8',
        height: 8,
    },
    thumb: {
        height: 30,
        width: 30,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: -11,
        marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },

    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
        top: -50,
        "& > span": {
            width: '42px !important',
            height: '42px !important'
        }
    },
    track: {
        height: 8,
        borderRadius: 4,
    },
    rail: {
        height: 8,
        borderRadius: 4,
    },
})(Slider);


const AddDetails = ({ classes, step, setStep, prevStep, setDetailsComplete, stateChanged, setStateChanged }) => {

    const campaignDetails = useSelector((state) => state.createCampaignReducer.campaignDetails);
    const userDetails = useSelector((state) => state.authReducer.userDetails);
    // const countryStates = useSelector((state) => state.commonReducer.countryStates);
    const dispatch = useDispatch();
    const navigate = useNavigate

    const { commission } = JSON.parse(sessionStorage.getItem('campaignCharges'))

    const [campaignName, setCampaignName] = useState(campaignDetails.campaignName);
    const [customName, setCustomName] = useState(campaignDetails.customName)
    const [campaignNameError, setCampaignNameError] = useState(false);
    const [campaignType, setCampaignType] = useState(campaignDetails.campaignType);
    const [noOfPosts, setNoOfPosts] = useState(campaignDetails.noOfPosts);
    const [noOfPostsError, setNoOfPostsError] = useState(false);
    const [perPost, setPerPost] = useState(campaignDetails.perPost);
    const [perPostError, setPerPostError] = useState(false);
    const [campaignExpiry, setCampaignExpiry] = useState(campaignDetails.expirable);
    const [startDate, setStartDate] = useState(campaignDetails.startDate !== "" ? (new Date(campaignDetails.startDate)) : new Date(now()));
    const [startDateError, setStartDateError] = useState(false);
    const [endDate, setEndDate] = useState(campaignDetails.endDate !== "" ? (new Date(campaignDetails.endDate)) : null);
    const [endDateError, setEndDateError] = useState(false);
    const [location, setLocation] = useState(campaignDetails.location);
    const [locationError, setLocationError] = useState(false);
    const [network, setNetwork] = useState(campaignDetails.network);
    const [networkError, setNetworkError] = useState(false);
    const [limitPosting, setLimitPosting] = useState(campaignDetails.limitPosting);
    const [limitPostingNumber, setLimitPostingNumber] = useState(campaignDetails.limitPostingNumber);
    const [image, setImage] = useState(campaignDetails.image);
    const [imageName, setImageName] = useState(campaignDetails.imageName);
    const [imageError, setImageError] = useState(false);
    const [csvFile, setCsvFile] = useState(campaignDetails.csvFile);
    const [csvFileName, setCsvFileName] = useState(campaignDetails.csvFileName);
    const [csvFileError, setCsvFileError] = useState("");
    const [csvStatusError, setCsvStatusError] = useState(false);
    const [storeSelection, setStoreSelection] = useState(campaignDetails.storeSelection);
    const [addressData, setAddressData] = useState(campaignDetails.addressData);
    const [addressDataError, setAddressDataError] = useState(false);
    const [addressDataErrorMsg, setAddressDataErrorMsg] = useState("");
    const [showModal, setModal] = useState(false);
    const [csvAPIResponse, setCsvAPIResponse] = useState("");
    const [btnLoading, setBtnLoading] = useState(false);
    const [brandInstaHandle, setBrandInstaHandle] = useState(campaignDetails.brandInstaHandle);
    const [brandInstaHandleError, setBrandInstaHandleError] = useState(false);
    const [brandInstaHashtags, setBrandInstaHashtags] = useState(campaignDetails.brandInstaHashtags);
    const [brandInstaHashtagsError, setBrandInstaHashtagsError] = useState(false);
    const [brandMoreHashtags, setBrandMoreHashtags] = useState(campaignDetails.brandMoreHashtags);
    const [brandMoreHashtagsError, setBrandMoreHashtagsError] = useState(false);
    const [value, setValue] = useState(null);
    const [minFollowersError, setMinFollowersError] = useState(false);
    const [minFollowers, setMinFollowers] = useState(campaignDetails.minFollowers);
    const [postDuration, setPostDuration] = useState(campaignDetails.postDuration);
    const [postDurationError, setPostDurationError] = useState(false);
    const [hiddenCampaign, setHiddenCampaign] = useState(campaignDetails.hiddenCampaign);
    const [purchaseRequired, setPurchaseRequired] = useState(campaignDetails.purchaseRequired);
    const [appointmentOnly, setAppointmentOnly] = useState(campaignDetails.appointmentOnly);
    const [postEventDay, setPostEventDay] = useState(campaignDetails.postEventDay);
    const [isRecievedDiscount, setIsRecievedDiscount] = useState(campaignDetails.is_recieved_discount)
    const [recievedDiscount, setRecievedDiscount] = useState(campaignDetails.recieved_discount)
    const [recievedDiscountCouponCode, setRecievedDiscountCouponCode] = useState(campaignDetails.recieved_discount_coupon_code)
    const [recievedDiscounterror, setrecievedDiscounterror] = useState(false)
    const [recievedDiscountCouponCodeerror, setrecievedDiscountCouponCodeerror] = useState(false)
    const [isMustBe21Older, setIsMustBe21OrOlder] = useState(campaignDetails.isMustBe21OrOlder)

    const handleImage = async (val, afterEdited = false) => {

        setImageError(false)
        checkformState(val)
        if (!val) {
            setImage('')
            setImageName('')
            return
        }

        if (val[0]?.name) {
            setImageName(val[0].name);
        }

        const myReader = new FileReader();

        if (afterEdited) {
            setImage(val)
        } else {
            myReader.readAsDataURL(val[0]);
        }

        myReader.onloadend = function (loadEvent) {
            setImage(loadEvent.target.result)
        }
    }

    const handleCsvFile = (val) => {
        const myReader = new FileReader();
        myReader.onloadend = function (loadEvent) {
            setCsvFile(loadEvent.target.result);

        };
        myReader.readAsDataURL(val[0]);


        setCsvFileError(false);
        // setCsvFile(URL.createObjectURL(val[0]));
        setCsvFileName(val[0].name);
    }

    const validateForm = () => {
        setCampaignNameError(false);
        setNoOfPostsError(false);
        setPerPostError(false);
        setStartDateError(false);
        setEndDateError(false);
        setLocationError(false);
        setNetworkError(false);
        setImageError(false);
        setCsvFileError("");
        setAddressDataError(false);
        setAddressDataErrorMsg("");
        setBrandInstaHandleError(false)
        setBrandInstaHashtagsError(false)
        setBrandMoreHashtagsError(false);
        setMinFollowersError(false);
        setPostDurationError(false);
        setrecievedDiscounterror(false);
        setrecievedDiscountCouponCodeerror(false);
        let campaignNameError = false, noOfPostsError = false, perPostError = false, startDateError = false, endDateError = false, locationError = false, networkError = false,
            imageError = false, csvFileError = false, addressDataError = false, brandInstaHandleError = false, brandInstaHashtagsError = false, brandMoreHashtagsError = false,
            minFollowersError = false, postDurationError = false, recievedDiscounterr = false, recievedDiscountCouponCodeerr = false;

        if ((Validate.notNullValidation(noOfPosts) === false) || parseInt(noOfPosts) <= 0 || Validate.numberValidation(noOfPosts) === false) {
            noOfPostsError = true;
        } else {
            noOfPostsError = false;
        }

        if ((Validate.notNullValidation(perPost) === false) || parseInt(perPost) <= 0 || Validate.numberValidation(perPost) === false) {
            perPostError = true;
        } else {
            perPostError = false;
        }

        if ((!Validate.notNullValidation(minFollowers)) || !Validate.minimumFollowersValidation(minFollowers) || !Validate.numberValidation(minFollowers)) {
            minFollowersError = true;
        } else {
            minFollowersError = false;
        }

        if ((!Validate.notNullValidation(postDuration)) || !Validate.postDurationValidtion(postDuration) || !Validate.numberValidation(postDuration)) {
            postDurationError = true;
        } else {
            postDurationError = false;
        }

        if ((Validate.notNullValidation(campaignName) === false) || (Validate.fieldLengthValidation(campaignName, 255) === false)) {
            campaignNameError = true;
        } else {
            campaignNameError = false;
        }

        if (isRecievedDiscount) {
            if ((!Validate.notNullValidation(recievedDiscount)) || !Validate.recievedDiscountValidation(recievedDiscount) || !Validate.numberValidation(recievedDiscount)) {
                recievedDiscounterr = true;
            } else {
                recievedDiscounterr = false;
            }

            if ((!Validate.notNullValidation(recievedDiscountCouponCode)) || !Validate.recievedDiscountCoupenCodeValidation(recievedDiscountCouponCode)) {
                recievedDiscountCouponCodeerr = true;
            } else {
                recievedDiscountCouponCodeerr = false;
            }
        }


        // if(Validate.notNullValidation(startDate) === false) {
        //     startDateError = true;
        // } else {
        //     startDateError = false;
        // }

        // if(Validate.notNullValidation(endDate) === false) {
        //     endDateError = true;
        // } else {
        //     endDateError = false;
        // }

        if (campaignExpiry) {
            if ((moment(startDate).format("MM/DD/YYYY") === moment(endDate).format("MM/DD/YYYY")) || (Validate.notNullValidation(endDate) === false) || (Validate.notNullValidation(startDate) === false)) {
                startDateError = true;
                endDateError = true;
            } else {
                startDateError = false;
                endDateError = false;
            }
        }

        // if (Validate.notNullValidation(location) === false) {
        //     locationError = true;
        // } else {
        //     locationError = false;
        // }

        if (Validate.notNullValidation(network) === false) {
            networkError = true;
        } else {
            networkError = false;
        }

        if (Validate.notNullValidation(image) === false) {
            imageError = true;
        } else {
            if (!imageName.match(/\.(jpg|jpeg|png)$/)) {
                imageError = true;
            } else {
                imageError = false;
            }
        }

        if (campaignType === "302") {
            if (storeSelection === "Many") {
                if (Validate.notNullValidation(csvFile) === false) {
                    csvFileError = "Please upload a CSV file";
                } else {
                    if (!csvFileName.match(/(.*?)\.(csv)$/)) {
                        csvFileError = "Invalid file uploaded";
                    } else {
                        csvFileError = "";
                    }
                }
            } else {
                let arrLength = addressData.length;
                addressData.map((data) => {
                    let address = data.address;
                    let state = data.state;
                    let city = data.city;
                    let zipcode = data.zipcode;
                    // let lat = data.latitude;
                    // let long = data.longitude;

                    if (Validate.notNullValidation(address) === false) {
                        data.addressError = "Address cannot be empty";
                        addressDataError = true;
                    }
                    // else if(Validate.fieldLengthValidation(address, 510) === false) {
                    //     data.addressError = "Address cannot be more than 150 characters and cannot contain special characters";
                    //     addressDataError = true;
                    // }
                    else {
                        data.addressError = "";
                        addressDataError = false;
                    }

                    if (Validate.notNullValidation(state) === false) {
                        data.stateError = "State cannot be empty";
                        addressDataError = true;
                    } else if (Validate.fieldLengthValidation(state, 50) === false) {
                        data.stateError = "State name cannot be more than 50 characters";
                        addressDataError = true;
                    } else {
                        data.stateError = "";
                        addressDataError = false;
                    }

                    if (Validate.notNullValidation(city) === false) {
                        data.cityError = "City cannot be empty";
                        addressDataError = true;
                    } else if (Validate.fieldLengthValidation(city, 50) === false) {
                        data.cityError = "City name cannot be more than 50 characters";
                        addressDataError = true;
                    } else {
                        data.cityError = "";
                        addressDataError = false;
                    }

                    if (Validate.notNullValidation(zipcode) === false) {
                        data.zipcodeError = "Zipcode cannot be empty";
                        addressDataError = true;
                    } else if (Validate.zipcodeValidation(zipcode) === false) {
                        data.zipcodeError = "Invalid zipcode";
                        addressDataError = true;
                    } else {
                        data.zipcodeError = "";
                        addressDataError = false;
                    }

                    // if(Validate.notNullValidation(lat) === false) {
                    //     data.latitudeError = "Latitude cannot be empty";
                    //     addressDataError = true;
                    // } else if(Validate.latitudeValidation(lat) === false) {
                    //     data.latitudeError = "Invalid coordinates";
                    //     addressDataError = true;
                    // } else {
                    //     data.latitudeError = "";
                    //     addressDataError = false;
                    // }

                    // if(Validate.notNullValidation(long) === false) {
                    //     data.longitudeError = "Longitude cannot be empty";
                    //     addressDataError = true;
                    // } else if(Validate.longitudeValidation(long) === false) {
                    //     data.longitudeError = "Invalid coordinates";
                    //     addressDataError = true;
                    // } else {
                    //     data.longitudeError = "";
                    //     addressDataError = false;
                    // }

                    if (data.addressError !== "" || data.stateError !== "" || data.cityError !== "" || data.zipcodeError !== "") {
                        addressDataError = true;
                    }
                })

                if (!addressDataError) {
                    const uniqueValues = new Set(addressData.map(v => v.address));
                    if (uniqueValues.size < addressData.length) {
                        setAddressDataErrorMsg("Address details should be different for every address");
                        addressDataError = true;
                    } else {
                        addressDataError = false;
                    }
                }
                setAddressData([...addressData]);
            }
        }

        if (Validate.notNullValidation(brandInstaHandle) === false) {
            brandInstaHandleError = true;
        } else {
            brandInstaHandleError = false;
        }

        if (Validate.notNullValidation(brandInstaHashtags) === false) {
            brandInstaHashtagsError = true;
        } else {
            brandInstaHashtagsError = false;
        }

        if (brandMoreHashtags.length > 0 && !Validate.fieldLengthValidation(brandMoreHashtags, 2000)) {
            brandMoreHashtagsError = true;
        } else {
            brandMoreHashtagsError = false;
        }

        setCampaignNameError(campaignNameError);
        setNoOfPostsError(noOfPostsError);
        setPerPostError(perPostError);
        setStartDateError(startDateError);
        setEndDateError(endDateError);
        setLocationError(locationError);
        setImageError(imageError);
        setCsvFileError(csvFileError);
        setAddressDataError(addressDataError);
        setBrandInstaHandleError(brandInstaHandleError);
        setBrandMoreHashtagsError(brandMoreHashtagsError);
        setMinFollowersError(minFollowersError);
        setPostDurationError(postDurationError);
        setrecievedDiscounterror(recievedDiscounterr);
        setrecievedDiscountCouponCodeerror(recievedDiscountCouponCodeerr);

        if (campaignNameError || noOfPostsError || perPostError || startDateError || endDateError || locationError
            || imageError || csvFileError || addressDataError || brandInstaHandleError || brandInstaHashtagsError
            || brandMoreHashtagsError || minFollowersError || postDurationError || recievedDiscounterr || recievedDiscountCouponCodeerr) {
            return false;
        } else {
            return true;
        }

    }

    const validateCsvAddress = async (type) => {
        setBtnLoading(true);
        setCsvStatusError(false);
        setAddressDataError(false);

        let csvStatusError = false, addressDataError = false;

        if (type === "CSV") {
            const data = await PostCSVValidateAPI({ csvFileName: csvFileName, csvFile: csvFile })
            setBtnLoading(false);
            if (data.status === "SUCCESS") {
                if (data.data.un_success_rows > 0) {
                    setCsvFileError("Invalid address details");
                    csvStatusError = true;
                    setCsvAPIResponse(data);
                    setModal(true);
                    return false;
                } else {
                    csvStatusError = false;
                    return true;
                }
            } else {
                csvStatusError = true;
                setCsvFileError("Invalid data structure, please follow our sample data structure");
                return false;
            }

        } else {
            setBtnLoading(true);
            const data = await PostAddressValidateAPI({ addressData: addressData });
            setBtnLoading(false);
            if (data.status === "SUCCESS") {
                data.data.map((address, index) => {
                    if (address.status !== "Success") {
                        let addresses = [...addressData]
                        addresses[index]['addressError'] = address.messages.address;
                        addresses[index]['cityError'] = address.messages.city;
                        addresses[index]['stateError'] = address.messages.state;
                        // addresses[index]['latitudeError'] = address.messages.lat_long;
                        // addresses[index]['longitudeError'] = address.messages.lat_long;
                        addresses[index]['zipcodeError'] = address.messages.zip_code;
                        setAddressData(addresses);

                        addressDataError = true;
                    } else {
                        addressDataError = false;
                    }
                });
            } else {
                addressDataError = false;
            }

            if (!addressDataError) {
                return true;
            } else {
                return false;
            }
        }
    }

    const handleSubmit = async () => {

        if (brandMoreHashtags.length > 0) {
            setBrandMoreHashtags(brandMoreHashtags.replace(/(\r\n|\n|\r)/gm, ","))
        }

        let isValidCsvAddress = true;
        let isValid = validateForm();
        if (isValid) {
            if (campaignType === "302") {
                if (storeSelection === "Many") {
                    isValidCsvAddress = await validateCsvAddress("CSV");
                } else {
                    isValidCsvAddress = await validateCsvAddress("ADDRESS");
                }

            }
            if (isValidCsvAddress) {

                let hashtagFormatting = "";
                if (brandMoreHashtags !== undefined && brandMoreHashtags.length > 0) {
                    hashtagFormatting = brandMoreHashtags.replace(/(\r\n|\n|\r)/gm, ",").split(',')
                    hashtagFormatting = hashtagFormatting.filter(Boolean);
                    hashtagFormatting = hashtagFormatting.map(i => (i.charAt(0) !== '#' ? '#' + i : i));
                    hashtagFormatting = hashtagFormatting.toString();
                }

                dispatch(insertCampaignDetails({
                    campaignName: campaignName,
                    customName: customName,
                    campaignType: campaignType,
                    image: image,
                    imageName: imageName,
                    csvFile: csvFile,
                    csvFileName: csvFileName,
                    addressData: addressData,
                    noOfPosts: noOfPosts,
                    perPost: perPost,
                    payoutAmount: (noOfPosts * perPost * commission).toFixed(2),
                    storeSelection: storeSelection,
                    startDate: startDate,
                    endDate: endDate,
                    expirable: campaignExpiry,
                    location: location,
                    network: network,
                    limitPosting: limitPosting,
                    limitPostingNumber: limitPostingNumber,
                    brandInstaHandle: brandInstaHandle,
                    brandInstaHashtags: brandInstaHashtags,
                    brandMoreHashtags: hashtagFormatting,
                    minFollowers: minFollowers,
                    postDuration: postDuration,
                    hiddenCampaign: hiddenCampaign,
                    purchaseRequired: purchaseRequired,
                    appointmentOnly: appointmentOnly,
                    postEventDay: postEventDay,
                    is_recieved_discount: isRecievedDiscount,
                    recieved_discount: recievedDiscount,
                    recieved_discount_coupon_code: recievedDiscountCouponCode,
                    isMustBe21OrOlder: isMustBe21Older
                }));
                setDetailsComplete(true);
                dispatch(insertProgressDetails({
                    detailsComplete: true,
                    billingComplete: false,
                    reviewComplete: false,
                    step: step !== 3 ? step + 1 : step
                }));
                if (step !== 3) {
                    setStep(step + 1);
                }
            }
        }
    }

    const handleChangeLimitPostingNumber = (event, newValue) => {
        setLimitPostingNumber(newValue);
    };


    const resetFile = () => {
        setCsvFile("");
        setCsvFileName("");

        let csvInput = document.getElementById("csv-upload");
        if (csvInput != null) {
            document.getElementById("csv-upload").value = null;
        }
    };

    const addAddress = (i) => {
        setAddressData([...addressData, {
            id: Math.random(),
            arraysI: i,
            address: "",
            state: "",
            city: "",
            zipcode: "",
            // latitude: "",
            // longitude: "",
            addressError: "",
            stateError: "",
            cityError: "",
            zipcodeError: "",
            // latitudeError: "",
            // longitudeError: ""
        }])
    };

    const deleteAddress = (id) => {
        setAddressData(addressData.filter(item => item.id !== id))
    }

    const handleChangeAddress = (setNo, property, locationDetails, fetchData = false) => {
        if (!fetchData) {
            let addresses = [...addressData]
            addresses[setNo][property] = value
            setAddressData(addresses);
        } else {
            if (!locationDetails.data.value) {
                toast.error("Couldn't fetch details for your place, please select a different place")
                return
            }
            GetPlaceDetailsAPI(locationDetails.data.value).then(data => {
                let addressGoogle = getAddress(data);
                let addresses = [...addressData]
                addresses[setNo]['address'] = locationDetails.data.label;
                addresses[setNo]['addressReal'] = locationDetails.data.label;
                addresses[setNo]['state'] = addressGoogle.state;
                addresses[setNo]['zipcode'] = addressGoogle.postcode;
                addresses[setNo]['city'] = addressGoogle.city;
                setAddressData(addresses);
            })
        }
    }

    const getAddress = (googleData) => {
        let address1 = '';
        let postcode = '';
        let state = '';
        let city = '';
        let country = '';

        for (const component of googleData.address_components) {
            const componentType = component.types[0];
            switch (componentType) {
                case "street_number": {
                    address1 = `${googleData.formatted_address} ${address1}`;
                    break;
                }

                case "route": {
                    address1 += component.short_name;
                    break;
                }

                case "postal_code": {
                    postcode = `${component.long_name}${postcode}`;
                    break;
                }

                case "locality":
                    city = component.long_name;
                    break;
                case "neighborhood":
                    city = component.long_name;
                    break;
                case "administrative_area_level_2": {
                    state = component.long_name;
                    break;
                }
                case "administrative_area_level_1": {
                    state = component.long_name;
                    break;
                }
                case "country":
                    country = component.long_name;
                    break;
            }
        }

        return {
            address: address1,
            postcode: postcode,
            state: state,
            country: country,
            city: city
        }
    }

    const checkLettersValues = (int, variable = '', setVariable) => {
        const re = /^\d*[1-9]\d*$/;
        if (variable === 'no' || variable === 'price') {
            let sum = 0
            if (variable === 'no') {
                sum = (int * perPost * 1.3)
                if (sum > 999999) return
            } else {
                sum = (int * noOfPosts * 1.3)
                if (sum > 999999) return
            }
        }
        if ((int === '' || re.test(int))) {
            setVariable(int);
        }
    }

    const checkformState = (value) => {
        if (value !== "" && value !== null) {
            setStateChanged(true);
        } else {
            setStateChanged(false)
        }
    }

    const handleCustomName = () => {
        setCustomName(!customName);
        if (!customName) {
            setCampaignName("")
        } else {
            setCampaignName("Promote the Business")
        }
    }

    const handleMoreHashtags = (hashtags) => {
        const re = /^[a-zA-Z0-9\s,_#]*$/;
        if ((hashtags === '' || re.test(hashtags))) {
            let commaSeperatedString = hashtags.split(/[ ,]+/).join(',');
            setBrandMoreHashtags(commaSeperatedString)
        }
    }

    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const handleChnageIsDiscount = () => {
        setIsRecievedDiscount(!isRecievedDiscount)
        setrecievedDiscounterror(false)
        setrecievedDiscountCouponCodeerror(false)
        setRecievedDiscount('')
        setRecievedDiscountCouponCode('')
    }

    useEffect(() => {
        if (userDetails.is_verified) {
            if (userDetails.status_id === 1901) {
                navigate('/get-started')
            }
            if (userDetails.status_id === 1902) {
                GetCardsByBrandAPI().then(data => {
                    if (data !== undefined) {
                        dispatch(getPreviousCards(data));
                    }
                }).catch(err => (err.response) && err.response.data.error);
                GetStatesAPI().then(data => {
                    if (data.data.length > 0) {
                        dispatch(getCountryStates(data.data));
                    }
                }).catch(err => (err.response) && err.response.data.error);
                GetHandleAndHashtagAPI().then(data => {
                    if (data.status === "SUCCESS") {
                        let handle = data.data.instagram_handle;
                        let hashtags = data.data.instagram_hashtag;
                        if (!!handle) {
                            handle = handle.replace('@', '');
                            setBrandInstaHandle(handle)
                        }
                        if (!!hashtags) {
                            hashtags = hashtags.replace(/#/g, '');
                            setBrandInstaHashtags(hashtags)
                        }

                    }
                })
            }
        } else {
            navigate('/signup-verification')
        }

    }, [GetCardsByBrandAPI, userDetails]); // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <>
            <div className={classes.campaignFormTop}>
                <div className={classes.campaignFormRight}>

                    <div className={classes.formGroup}>
                        <h3 className={classes.formHeadingStyles} id="campaign-type-label">1. Type of Campaign:</h3>
                        <select name="" defaultValue={campaignType} id="campaign-type-select" className={classes.formInputStyles} onChange={e => { setCampaignType(e.target.value); checkformState(e.target.value); }} style={{ borderRadius: 15, border: '1px solid #B7B7B7', marginBottom: 10, marginTop: 10 }}>
                            <option value="302">Brick and Mortar</option>
                            <option value="301">Direct To Customer</option>

                        </select>
                        {/* {campaignTypeError && <span className={classes.fieldError}>Campaign Type should be under 50 characters</span>} */}
                        {/* {campaignDescriptionError && <span className={classes.fieldError}>Campaign Name should be under 255 characters</span>} */}
                    </div>
                    {/* <div className={classes.formGroup} style={{ display: 'none' }}>
                        <h3 className={classes.formHeadingStyles} id="campaign-name-label">2. Campaign Name:</h3>
                        {customName ?
                            <input id="campaign-name" className={`${classes.formInputStyles} ${campaignNameError && classes.invalidFormInputs}`} style={{ borderRadius: 15, border: '1px solid #B7B7B7', marginBottom: 10, marginTop: 10 }} placeholder="Campaign name" type="text" value={campaignName} onChange={e => { setCampaignName(e.target.value); checkformState(e.target.value) }} autoComplete="off" />
                            :
                            <select id="campaign-name-select" className={classes.formInputStyles} defaultValue={campaignName} value={campaignName} onChange={e => { setCampaignName(e.target.value); checkformState(e.target.value) }} style={{ borderRadius: 15, border: '1px solid #B7B7B7', marginBottom: 10, marginTop: 10 }}>
                                <option value="Promote the Business">Promote the Business</option>
                                <option value="Increase User Posts">Increase User Posts</option>
                                <option value="We Love Instagram">We Love Instagram</option>
                                <option value="We Love our Community">We Love our Community</option>
                                <option value="Engaging our Community with Love">Engaging our Community with Love</option>
                            </select>
                        }
                        {campaignNameError && <span className={classes.fieldError} id="campaign-name-error-message">Campaign Name is invalid</span>} */}
                    {/* {campaignDescriptionError && <span className={classes.fieldError}>Campaign Name should be under 255 characters</span>} */}
                    {/* <div className={classes.termsOfServiceContainer}>
                            <input className={classes.rememeberCheckbox} type="checkbox" id="campaign-name" onChange={handleCustomName} checked={customName} />
                            <label htmlFor="campaign-name" id="campaign-name-checkbox">Specify Campaign Name</label>
                        </div>
                    </div>  */}


                    <div className={`${classes.formGroup} ${classes.spendingContainer}`}>
                        <div className={classes.inputSide}>
                            <h3 className={classes.formHeadingStyles} id="spending-label">2. Decide how much to spend:</h3>

                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div className={`${classes.formInputContStyles} ${classes.priceInputResponsive} ${perPostError && classes.invalidFormInputs}`}>
                                    <label htmlFor="per_post" id="per-post-label" className={classes.formInputLabels} style={{ width: '30%' }}>$ per post</label>
                                    <input className={`${classes.formInputStyles} ${classes.priceInputs}`} id="per_post" type="text" value={perPost} onChange={e => { checkLettersValues(e.target.value, 'price', setPerPost); checkformState(e.target.value) }} autoComplete="off" />
                                </div>
                                {/* <PrettoSlider id="per-post-slider" className={classes.perPostSlider} aria-label="pretto slider" valueLabelDisplay="on" min={0} max={1000} value={Number.isInteger(parseInt(perPost)) ? parseInt(perPost) : 0} onChange={handleChangePerPost} /> */}
                            </div>
                            {perPostError && <span className={classes.fieldError}>Invalid price per post</span>}

                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div className={`${classes.formInputContStyles} ${classes.priceInputResponsive} ${noOfPostsError && classes.invalidFormInputs}`}>
                                    <label htmlFor="no_of_posts" id="no-of-posts-label" className={classes.formInputLabels} style={{ width: '30%' }}># of posts</label>
                                    <input className={`${classes.formInputStyles} ${classes.priceInputs}`} id="no_of_posts" type="text" value={noOfPosts} onChange={e => { checkLettersValues(e.target.value, 'no', setNoOfPosts); checkformState(e.target.value) }} autoComplete="off" />
                                </div>
                                {/* <PrettoSlider id="no-of-posts-slider" className={classes.noOfPostsSlider} aria-label="pretto slider" valueLabelDisplay="on" min={0} max={1000} value={Number.isInteger(parseInt(noOfPosts)) ? parseInt(noOfPosts) : 0} onChange={handleChangenoOfPost} /> */}
                            </div>
                            {noOfPostsError && <span className={classes.fieldError} id="noOfPost-error-message">Invalid no of posts</span>}

                        </div>
                        <div className={classes.amountPayoutContainer}>
                            <div className={classes.amountPayout}>
                                <span className={classes.amountText} id="campaign-cost-label">Campaign Cost</span>
                                <span className={classes.amount} id="campaign-cost">{'$' + Helpers.numberWithCommas(Helpers.calculateBudgetWithStripeCharge(noOfPosts * perPost * commission))}</span>
                            </div>
                        </div>
                    </div>


                    <div className={classes.formGroup}>
                        <div className={classes.termsOfServiceContainer}>
                            <input className={classes.rememeberCheckbox} type="checkbox" id="remember" value={campaignExpiry} onChange={e => setCampaignExpiry(!campaignExpiry)} />
                            <label htmlFor="remember" id="remember-label">Specify Campaign Expiry Dates</label>
                        </div>
                        {campaignExpiry &&
                            <div className={classes.formDates}>
                                <div className={`${classes.formInputContStyles} ${startDateError ? classes.invalidFormInputs : endDateError ? classes.invalidFormInputs : ""}`}>
                                    <label htmlFor="start_date" className={classes.formInputLabels} id="start-date-label">Start Date</label>
                                    <DatePicker className={`${classes.formInputStyles} ${classes.datePickerPlaceholder}`} minDate={moment()._d} placeholderText="MM/DD/YYYY" id="start_date" selected={startDate} onChange={date => { setStartDate(date); checkformState(date) }} autoComplete="off" />
                                    <label htmlFor="end_date" className={classes.formInputLabels} id="end-date-label">End Date</label>
                                    <DatePicker className={`${classes.formInputStyles} ${classes.datePickerPlaceholder}`} minDate={startDate !== "" && moment(startDate).add(1, 'days')._d} id="end_date" placeholderText="MM/DD/YYYY" selected={endDate} onChange={date => { setEndDate(date); checkformState(date) }} autoComplete="off" disabled={startDate === "" ? true : false} />
                                    {/* <label className={`${classes.formInputLabels} ${classes.cardCheck}`} style={{ border: 'none' }}><FontAwesomeIcon icon={faEdit} /></label> */}
                                </div>
                            </div>
                        } {(campaignExpiry && (startDateError || endDateError)) && <span className={classes.fieldError} id="invalidDate-error-message">Invalid start date or end date</span>}

                    </div>

                    <div className={classes.formGroup}>
                        <h3 className={classes.formHeadingStyles} id="audience-label">3. Choose target audience:</h3>
                        {campaignType !== "302" &&
                            <div className={`${classes.formInputContStyles} ${locationError && classes.invalidFormInputs}`}>
                                <label htmlFor="location" className={`${classes.formInputLabels} ${classes.labelResponsive}`} id="location-label">Location</label>
                                <input className={classes.formInputStyles} id="location" placeholder="United States" type="text" value={location} disabled={true} onChange={e => { setLocation(e.target.value); checkformState(e.target.value) }} readOnly={true} />
                                {/* <label className={`${classes.formInputLabels} ${classes.cardCheck}`} style={{ border: 'none' }}><FontAwesomeIcon icon={faEdit} /></label> */}
                            </div>
                        }

                        {/* {locationError && <span className={classes.fieldError}>Location cannot be empty</span>} */}

                        <div className={`${classes.formInputContStyles} ${networkError && classes.invalidFormInputs}`}>
                            <label htmlFor="network" className={`${classes.formInputLabels} ${classes.labelResponsive}`} id="network-label">Network</label>
                            <input className={classes.formInputStyles} id="network" placeholder="Instagram" type="text" value={"Instagram"} onChange={e => { setNetwork(e.target.value); checkformState(e.target.value) }} disabled={true} />
                            {/* <label className={`${classes.formInputLabels} ${classes.cardCheck}`} style={{ border: 'none' }}><FontAwesomeIcon icon={faEdit} /></label> */}
                        </div>
                        {networkError && <span className={classes.fieldError} id="network-error-message">Network cannot be empty</span>}
                    </div>

                    <div className={`${classes.formGroup} ${classes.imageResponsive}`}>
                        <ImageUploader image={image} imageName={imageName} setImage={setImage} imageError={imageError} setImageError={setImageError} handleImage={handleImage} campaignName={campaignName} campaignType={campaignType} />
                    </div>

                    <div className={classes.formGroup}>
                        <h3 className={classes.formHeadingStyles} id="limit-posting-heading">4. Limit Posting:</h3>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div className={`${classes.formInputContStyles} ${classes.limitPostingResponsive}`} style={{ width: (limitPosting === "No limit") && "100%" }}>
                                <label htmlFor="limit_posting" className={classes.formInputLabels} style={{ width: '60%' }} id="limit-input-label">Limit posts per user</label>
                                {/* <input className={`${classes.formInputStyles} ${classes.priceInputs}`} id="limit_posting" type="text" value={limitPosting} onChange={e => setLimitPosting(e.target.value)} autoComplete="off" /> */}
                                <select id="limit-posting-select" className={`${classes.formInputStyles} ${classes.limitPosting}`} onChange={e => { setLimitPosting(e.target.value); checkformState(e.target.value) }} style={{ border: 'none', fontSize: 15 }} value={limitPosting}>
                                    <option value="NoLimit">No Limit</option>
                                    <option value="Daily">Daily</option>
                                    <option value="Weekly">Weekly</option>
                                    <option value="Monthly">Monthly</option>
                                    {/* <option value="Once">Once</option> */}
                                    <option value="Campaign">One Time Only</option>
                                </select>
                            </div>
                            {limitPosting !== "NoLimit" &&
                                // {limitPosting !== "NoLimit" && limitPosting !== "Once" &&
                                <PrettoSlider id="limit-posting-slider" className={classes.limitPostingSlider} valueLabelDisplay="on" aria-label="pretto slider" min={1} max={5} value={limitPostingNumber} onChange={handleChangeLimitPostingNumber} />
                            }
                        </div>
                    </div>

                    <div className={classes.formGroup}>
                        <h3 className={classes.formHeadingStyles} id="limit-posting-heading">5. Brand's Instagram Handle:</h3>
                        <div className={`${classes.formInputContStyles} ${brandInstaHandleError && classes.invalidFormInputs}`}>
                            <label htmlFor="brand-insta-handle" className={`${classes.formInputLabels} ${classes.labelSocialResponsive}`} id="brand-insta-handle-label">@</label>
                            <input className={classes.formInputStyles} id="brand-insta-handle" type="text" value={brandInstaHandle} onChange={e => { setBrandInstaHandle(e.target.value); checkformState(e.target.value) }} disabled={true} />
                        </div>
                        {brandInstaHandleError && <span className={classes.fieldError} id="network-error-message">Instagram handle cannot be empty</span>}

                        <div className={`${classes.formInputContStyles} ${brandInstaHashtagsError && classes.invalidFormInputs}`}>
                            <label htmlFor="brand-insta-hashtag" className={`${classes.formInputLabels} ${classes.labelSocialResponsive}`} id="brand-insta-hashtag-label">#</label>
                            <input className={classes.formInputStyles} id="brand-insta-hashtag" type="text" value={brandInstaHashtags} onChange={e => { setBrandInstaHashtags(e.target.value); checkformState(e.target.value) }} disabled={true} />
                        </div>
                        {brandInstaHashtagsError && <span className={classes.fieldError} id="network-error-message">Instagram hashtag cannot be empty</span>}

                        <div className={`${classes.formInputContStyles} ${brandMoreHashtagsError && classes.invalidFormInputs}`} style={{ position: 'relative', height: 250 }}>
                            <label htmlFor="brand-more-hashtags" className={`${classes.formInputLabels} ${classes.labelSocialResponsive}`} id="brand-more-hashtag-label">#</label>
                            <textarea className={classes.formInputStyles} style={{ padding: 10, resize: 'none', fontSize: 16 }} id="brand-more-hashtags" type="text" value={brandMoreHashtags} onChange={e => { handleMoreHashtags(e.target.value); checkformState(e.target.value) }}></textarea>
                            <AddCircleIcon className={classes.circleIcon} id="plusicon" />
                        </div>
                        {brandMoreHashtagsError && <span className={classes.fieldError} id="brand-hashtags-message">Hashtags cannot be more than 2000 characters</span>}

                        <span className={classes.selectLabel} style={{ fontWeight: 'normal', fontSize: 10, marginTop: 5 }}>Note: Enter hashtags without # symbol and use space to seperate hashtags</span>
                    </div>

                    <div className={classes.formGroup}>
                        <h3 className={classes.formHeadingStyles} id="limit-posting-heading">6. Campaign Preferences:</h3>

                        <div className={`${classes.formInputContStyles} ${classes.minFollowersCont} ${minFollowersError && classes.invalidFormInputs}`}>
                            <label htmlFor="min-followers" className={`${classes.formInputLabels} ${classes.labelMinFollowers}`} id="min-followers-label">Minimum No of Followers</label>
                            <input className={`${classes.formInputStyles} ${classes.priceInputs}`} style={{ width: 150 }} id="min-followers" type="text" value={minFollowers} onChange={e => { checkLettersValues(e.target.value, '', setMinFollowers); checkformState(e.target.value) }} />
                        </div>
                        {minFollowersError && <span className={classes.fieldError} id="min-followers-error-message">Invalid minimum followers</span>}

                        <div className={classes.termsOfServiceContainer}>
                            <label htmlFor="hidden-campaign">
                                <input className={classes.rememeberCheckbox} type="checkbox" id="hidden-campaign" onChange={() => setHiddenCampaign(!hiddenCampaign)} checked={hiddenCampaign} />
                                Hidden campaign (User has to search for you in the app)
                            </label>
                        </div>

                        <div className={classes.termsOfServiceContainer}>
                            <label htmlFor="purchase-required">
                                <input className={classes.rememeberCheckbox} type="checkbox" id="purchase-required" onChange={() => setPurchaseRequired(!purchaseRequired)} checked={purchaseRequired} />
                                Purchase required
                            </label>
                        </div>

                        <div className={classes.termsOfServiceContainer}>
                            <label htmlFor="appointment-only">
                                <input className={classes.rememeberCheckbox} type="checkbox" id="appointment-only" onChange={() => setAppointmentOnly(!appointmentOnly)} checked={appointmentOnly} />
                                By appointment only
                            </label>
                        </div>

                        <div className={`${classes.formInputContStyles} ${classes.postDurationCont} ${postDurationError && classes.invalidFormInputs}`}>
                            <label htmlFor="post-duration" className={`${classes.formInputLabels} ${classes.labelPostDuration}`} id="post-duration-label">Post must stay up for</label>
                            <input className={`${classes.formInputStyles} ${classes.priceInputs}`} style={{ width: 100, borderRight: 'none' }} id="post-duration" type="text" value={postDuration} onChange={e => { checkLettersValues(e.target.value, '', setPostDuration); checkformState(e.target.value) }} />
                            <label htmlFor="post-duration" className={`${classes.formInputLabels} ${classes.labelPostDuration}`} style={{ width: 100 }} id="post-duration-label">days</label>
                        </div>
                        {postDurationError && <span className={classes.fieldError} id="post-duration-error-message">Invalid minimum days</span>}


                        <div className={classes.termsOfServiceContainer}>
                            <label htmlFor="post-day">
                                <input className={classes.rememeberCheckbox} type="checkbox" id="post-day" onChange={() => setPostEventDay(!postEventDay)} checked={postEventDay} />
                                Photo must post on the day of the event
                            </label>
                        </div>

                        <div className={classes.termsOfServiceContainer}>
                            <label htmlFor="is_recieved_discount">
                                <input className={classes.rememeberCheckbox} type="checkbox" id="is_recieved_discount" onChange={handleChnageIsDiscount} checked={isRecievedDiscount} />
                                Receive a
                                <input
                                    className={`${classes.formInputStyles} ${classes.priceInputs} ml-2`}
                                    style={{ width: 50, marginLeft: '5px', marginRight: '5px' }}
                                    id="recieved_discount"
                                    type="text"
                                    value={recievedDiscount}
                                    disabled={!isRecievedDiscount}
                                    onChange={e => {
                                        const inputValue = e.target.value;
                                        if (inputValue === '' || (Number.isInteger(Number(inputValue)) && Number(inputValue) >= 1 && Number(inputValue) <= 99)) {
                                            setRecievedDiscount(inputValue);
                                            checkformState(inputValue);
                                        }
                                    }}
                                />
                                % discount with coupon code
                                <input
                                    className={`${classes.formInputStyles} ${classes.priceInputs} ml-2`}
                                    style={{ width: 130, marginLeft: '5px', marginRight: '5px' }}
                                    id="recieved_discount_coupon_code"
                                    type="text"
                                    value={recievedDiscountCouponCode}
                                    disabled={!isRecievedDiscount}
                                    onChange={e => {
                                        const inputValue = e.target.value;
                                        if (/^[a-zA-Z0-9]{0,8}$/.test(inputValue)) {
                                            setRecievedDiscountCouponCode(inputValue);
                                            checkformState(inputValue);
                                        }
                                    }}
                                />
                            </label>
                        </div>
                        {recievedDiscounterror && <span className={classes.fieldError} id="recieveddiscount-error-message">Discount must be an integer between 1 and 99</span>}
                        {recievedDiscountCouponCodeerror && <span className={classes.fieldError} id="recieveddiscountcouponcode-error-message">Code must be an alphanumeric with between 3 and 8 characters</span>}

                        <div className={classes.termsOfServiceContainer}>
                            <label htmlFor="isMustBe21Older">
                                <input className={classes.rememeberCheckbox} type="checkbox" id="isMustBe21Older" onChange={() => setIsMustBe21OrOlder(!isMustBe21Older)} checked={isMustBe21Older} />
                                Must be 21 or older
                            </label>
                        </div>
                    </div>


                    {campaignType === "302" &&
                        <div className={classes.formGroup}>
                            <h3 className={classes.formHeadingStyles} id="stores-heading">7. How many stores:</h3>
                            <div className={classes.storesCont}>
                                <Button id="few-stores-btn" className={storeSelection === "Few" ? classes.storeActiveButton : classes.storeButton} name={"Few"} onClick={() => { setStoreSelection("Few"); resetFile() }} />
                                <Button id="many-stores-btn" className={storeSelection === "Many" ? classes.storeActiveButton : classes.storeButton} name={"Many"} onClick={() => setStoreSelection("Many")} />
                            </div>
                            {storeSelection === "Many" ?
                                <>
                                    <div className={classes.csvUploadingCont}>
                                        <div className={classes.uploadStoreCont} id="csv-upload-txt">
                                            Upload CSV
                                            <input id="csv-upload" className={classes.csvUploadButton} onChange={e => { handleCsvFile(e.target.files); checkformState(e.target.value) }} type="file" accept=".csv" />
                                        </div>
                                        {csvFileName !== "" &&
                                            <div className={classes.fileInfo}>
                                                <img src={ExcelIcon} alt="Excel" width="40px" height="40px" id="csv-icon" />
                                                <p className={classes.fileName} id="csv-filename">{csvFileName}</p>
                                                <FontAwesomeIcon icon={faTimes} id="cross-icon" color={"#3C3939"} style={{ cursor: 'pointer' }} onClick={() => resetFile()} />
                                            </div>
                                        }
                                    </div>
                                    <span className={classes.csvToolTip} id="csv-tooltip">Allowed types: CSV (Up to 64MB). <a href={SampleCSV} id="sample-csv" className={classes.downloadCSV} download={true}>Download Sample.</a></span>
                                    <br />
                                    {csvFileError !== "" && <span className={classes.fieldError} style={{ paddingLeft: 0 }} id="field-error-message">{csvFileError}</span>}
                                </>
                                :
                                addressData.map((data, i) => {
                                    return (
                                        <>
                                            <div className={classes.fewAddressCont} key={i}>
                                                <div className={`${classes.formInputContStyles} ${(i === data.arraysI) && (data.addressError !== "" && data.addressError !== null) && classes.invalidFormInputs}`} style={{ marginTop: 20, position: 'relative', top: 0, left: 0 }}>
                                                    <label htmlFor="address" className={classes.formInputLabels} id="address-label" style={{ width: '14%' }}>Address</label>
                                                    <MapDropdown inputValue={data.address} onChange={e => { handleChangeAddress(i, 'address', e, true); checkformState(e.label) }} />
                                                    {/* <GooglePlacesAutocomplete
                                                        escapeClearsValue
                                                        apiKey={'AIzaSyDIds8_Z8rwA8LKTLYyYDiZ1T_OpbbWGVg'}
                                                        autocompletionRequest={{
                                                            componentRestrictions: {
                                                                country: ['us']
                                                            }
                                                        }}
                                                        debounce={1000}
                                                        contentEditable={true}
                                                        selectProps={{
                                                            backspaceRemovesValue: true,
                                                            isClearable: true,
                                                            defaultInputValue: data.address,
                                                            value: value,
                                                            onChange: e => { setValue(e); handleChangeAddress(i, 'address', e, true); checkformState(e);},
                                                            styles: {
                                                                container: (provided) => ({
                                                                    ...provided,
                                                                    width: '74%',
                                                                    border: 'none',
                                                                }),
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    width: '100%',
                                                                    border: 'none',
                                                                    marginTop: '1.25%',
                                                                    '&:hover, :focus-visible, :focus-within': {
                                                                        border: 'none !important',
                                                                        boxShadow: 'none !important',
                                                                        outline: 'none'
                                                                    },
                                                                    outline: 'none',
                                                                }),
                                                                placeholder: (provided) => ({
                                                                    ...provided,
                                                                    display: 'none'
                                                                }),
                                                                input: (provided) => ({
                                                                    ...provided,
                                                                    color: '#000',
                                                                    width: 'auto',
                                                                    fontFamily: 'Poppins',
                                                                    fontStyle: 'normal',
                                                                    fontWeight: 'normal',
                                                                    fontSize: 20,
                                                                    boxSizing: 'border-box',
                                                                    border: 'none',
                                                                    outline: 'none',
                                                                    '&:hover, :focus, :visited': {
                                                                        border: 'none !important',
                                                                        boxShadow: 'none !important'
                                                                    },
                                                                }),
                                                                option: (provided) => ({
                                                                  ...provided,
                                                                  color: '#000',
                                                                  border: 'none',
                                                                  '&:hover': {
                                                                      border: 'none',
                                                                  },
                                                                }),
                                                                menu: (provided) => ({
                                                                    ...provided,
                                                                    // backgroundColor: 'none'
                                                                }),
                                                                singleValue: (provided) => ({
                                                                  ...provided,
                                                                  color: '#000',
                                                                  border: 'none',
                                                                }),
                                                            },
                                                        }}
                                                    /> */}
                                                    {/* <input className={classes.formInputStyles} id="address" value={data.address} onChange={e => { handleChangeAddress(i, 'address', e.target.value); checkformState(e.target.value)}} type="text" /> */}
                                                    {addressData.length !== 1 && <label className={`${classes.formInputLabels} ${classes.cardCheck}`} style={{ border: 'none' }}><FontAwesomeIcon icon={faTrashAlt} id="delete-icon" color="#A90000" onClick={() => deleteAddress(data.id)} /></label>}
                                                    {addressData.length - 1 === i && <AddCircleIcon className={classes.circleIcon} id="plusicon" onClick={() => addAddress(i + 1)} />}
                                                </div>
                                                {(i === data.arraysI) && data.addressError !== "" && <span className={classes.fieldError} style={{ paddingLeft: 0 }} id="address-error-message">{data.addressError}</span>}
                                                <div className={classes.longLatInputs}>
                                                    <div className={classes.zipCodeResponsive}>
                                                        <div className={`${classes.formInputContStyles} ${(i === data.arraysI) && (data.stateError !== "" && data.stateError !== null) && classes.invalidFormInputs}`}>
                                                            <label htmlFor="state" className={classes.formInputLabels} style={{ width: '46%' }} id="state-label">State</label>
                                                            <input className={classes.formInputStyles} id="state" value={data.state} onChange={e => { handleChangeAddress(i, 'state', e.target.value); checkformState(e.target.value) }} type='text' readOnly='true' />
                                                            {/* <select className={classes.formInputStyles} id="state" value={data.state} onChange={e => { handleChangeAddress(i, 'state', e.target.value); checkformState(e.target.value)}} style={{ border: 'none', fontSize: 15, padding: 10 }}>
                                                                {countryStates.length > 0 ? countryStates.map((country, index) => {
                                                                    return (
                                                                        <option value={country.name} key={index}>{country.name}</option>
                                                                    );
                                                                }) 
                                                                :
                                                                    <option value="CA">California</option>
                                                                }
                                                            </select> */}
                                                        </div>
                                                        {(i === data.arraysI) && data.stateError !== "" && <span className={classes.fieldError} style={{ paddingLeft: 0 }} id="state-error-message">{data.stateError}</span>}
                                                    </div>

                                                    <div className={classes.zipCodeResponsive}>
                                                        <div className={`${classes.formInputContStyles} ${(i === data.arraysI) && (data.cityError !== "" && data.cityError !== null) && classes.invalidFormInputs}`}>
                                                            <label htmlFor="city" className={classes.formInputLabels} style={{ width: '46%' }} id="city-label">City</label>
                                                            <input className={classes.formInputStyles} id="city" value={data.city} onChange={e => { handleChangeAddress(i, 'city', e.target.value); checkformState(e.target.value) }} type="text" readOnly='true' />
                                                            {/* <select className={classes.formInputStyles} id="city" value={data.city} onChange={e => handleChangeAddress(i, 'city', e.target.value)} style={{ fontSize: 15, padding: 10 }}>
                                                                <option value="Los Angeles">Los Angeles</option>
                                                                <option value="Miami">Miami</option>
                                                            </select> */}
                                                        </div>
                                                        {(i === data.arraysI) && data.cityError !== "" && <span className={classes.fieldError} style={{ paddingLeft: 0 }} id="city-error-message">{data.cityError}</span>}
                                                    </div>
                                                </div>
                                                <div className={classes.longLatInputs}>
                                                    <div className={`${classes.formInputContStyles} ${classes.zipCodeResponsive} ${(i === data.arraysI) && (data.zipcodeError !== "" && data.zipcodeError !== null) && classes.invalidFormInputs}`}>
                                                        <label htmlFor="zipcode" className={`${classes.formInputLabels}`} style={{ width: '46%' }} id="zipcode-label">Zip Code</label>
                                                        <input className={classes.formInputStyles} id="zipcode" value={data.zipcode} onChange={e => { handleChangeAddress(i, 'zipcode', e.target.value); checkformState(e.target.value) }} type="text" readOnly='true' />
                                                    </div>
                                                </div>
                                                {(i === data.arraysI) && data.zipcodeError !== "" && <span className={classes.fieldError} style={{ paddingLeft: 0 }} id="zipcode-error-message">{data.zipcodeError}</span>}

                                                {/* <div className={classes.longLatInputs}>
                                                    <div style={{ width: '47.5%' }}>
                                                        <div className={`${classes.formInputContStyles} ${(i === data.arraysI) && (data.latitudeError !== "" && data.latitudeError !== null) && classes.invalidFormInputs}`}>   
                                                            <label htmlFor="latitude" className={classes.formInputLabels} style={{ width: '46%' }} id="latitude-label">Latitude</label>
                                                            <input className={classes.formInputStyles} id="latitude" value={data.latitude} onChange={e => { handleChangeAddress(i, 'latitude', e.target.value); checkformState(e.target.value)}} type="text" />
                                                        </div>
                                                        {(i === data.arraysI) && data.latitudeError !== "" && <span className={classes.fieldError} style={{ paddingLeft: 0 }} id="latitude-error-message">{data.latitudeError}</span>}
                                                    </div>
                                                    <div style={{ width: '47.5%' }}>
                                                        <div className={`${classes.formInputContStyles} ${(i === data.arraysI) && (data.longitudeError !== "" && data.longitudeError !== null) && classes.invalidFormInputs}`}>   
                                                            <label htmlFor="longitude" className={classes.formInputLabels} style={{ width: '46%' }} id="longitude-label">Longitude</label>
                                                            <input className={classes.formInputStyles} id="longitude" value={data.longitude} onChange={e => {handleChangeAddress(i, 'longitude', e.target.value); checkformState(e.target.value)}} type="text" />
                                                        </div>
                                                        {(i === data.arraysI) && data.longitudeError !== "" && <span className={classes.fieldError} style={{ paddingLeft: 0 }} id="longitude-error-message">{data.longitudeError}</span>}
                                                    </div>
                                                </div> */}
                                            </div>
                                            <div className={classes.fieldError} style={{ marginTop: 10, paddingLeft: 0 }} id="address-errors">{addressDataErrorMsg}</div>
                                        </>
                                    )
                                })
                            }
                        </div>
                    }
                </div>

                <div className={classes.campaignFormLeft}>
                    <div className={classes.imageRightCont}>
                        <ImageUploader image={image} imageName={imageName} setImage={setImage} imageError={imageError} setImageError={setImageError} handleImage={handleImage} campaignName={campaignName} campaignType={campaignType} />
                    </div>
                    <div className={classes.createCampaignBottom}>
                        <Button className={classes.formSubmit} name={btnLoading ? <CircularProgress size={35} className={classes.btnLoading} /> : "Next"} onClick={() => handleSubmit()} disabled={btnLoading} />
                    </div>
                </div>
            </div>

            {showModal &&
                <CSVStatusModal csvResponse={csvAPIResponse} closeModal={() => setModal(false)} />
            }
            <ToastContainer theme='colored' />
        </>
    )
};

export default withStyles(styles)(AddDetails);