import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from "react-router-dom";
import ThumzupLogo from '../../assets/images/thumbzup-logo-blue.png'
import GetBrandsAPI from '../../services/Common/GetBrandsAPI';
import GetBrandDetailsAPI from '../../services/Common/GetBrandDetailsAPI';
import { brandView, updateStatus } from '../../store/actions/Auth/authActions';
import { TfiReload } from 'react-icons/tfi'
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Search from './Search'
import { AppContext } from '../../utils/AppContext';

const Header = () => {
    const location = useLocation();

    const { resetState } = useContext(AppContext);

    const brandName = useSelector((state) => (state.authReducer.userDetails.brand_name));
    const username = useSelector((state) => (state.authReducer.userDetails.first_name) + " " + (state.authReducer.userDetails.last_name));
    const email = useSelector((state) => (state.authReducer.userDetails.email));
    const typeId = useSelector((state) => (state.authReducer.userDetails.type_id));
    const userDetails = useSelector((state) => (state.authReducer.userDetails));
    const brandViewing = useSelector((state) => state.authReducer.brandView)

    const dispatch = useDispatch();

    const [brands, setBrands] = useState([]);

    const handleBrand = (brandDetails) => {
        if (brandDetails) {
            let brandId = brandDetails.brand_id;
            dispatch(brandView({ ...userDetails, brand_id: brandId, brand_name: brandDetails.name }, true))
        } else {
            dispatch(brandView({ ...userDetails, brand_id: null, brand_name: null }, false))
        }
    }

    useEffect(() => {
        if (typeId === 1802 || typeId === 1805 || typeId === 1806) {
            GetBrandsAPI().then(data => {
                if (data.status === 'SUCCESS') {
                    setBrands(data.data);
                }
            }).catch(err => err)
        }
        if (typeId === 1801) {
            GetBrandDetailsAPI(userDetails.brand_id).then(data => {
                if (data.status === 'SUCCESS') {
                    dispatch(updateStatus({
                        ...userDetails,
                        phone: data.data.phone_number,
                        instaHandle: data.data.instagram_handle,
                        instaHashtag: data.data.instagram_hashtag,
                        outOfFunds: data.data.campaign_out_of_budget
                    }))
                }
            }).catch(err => console.log(err))
        }
    }, [typeId])


    useEffect(() => {
        resetState();
    }, [location.pathname]);

    return (
        <div className="NavvBar">
            <nav className="navbar">
                <div className="container-fluid">
                    <div className="navii w-100">
                        <div className="row d-flex justify-content-between">
                            <div className="col-4 header-content">
                                <Link className="navbar-brand d-flex header-logo" to="/">
                                    <div className="brand-logoo">
                                        <img src={ThumzupLogo} id="thumzup-logo" width={60} height={60} alt="" />

                                    </div>
                                    <div className="brand-name" id="thumzup-txt">
                                        Thumzup
                                    </div>
                                </Link>
                                {userDetails?.outOfFunds &&
                                    <Link className='out-of-funds-btn' to="/campaigns?recharge=campaign">
                                        <TfiReload className='reload-icon' size={20} />
                                        Campaign Out Of Funds
                                    </Link>
                                }


                                {location.pathname === '/users' ?
                                    (<>
                                        <div className="withsearch">
                                            {(typeId === 1802 || typeId === 1806) ?
                                                <>
                                                    <Search />
                                                    <div className='search-bar-cont withsearchBrand'>
                                                        <Autocomplete
                                                            style={{ width: '100%' }}
                                                            disablePortal
                                                            id="combo-box-demo"
                                                            options={brands}
                                                            defaultValue={{ brand_id: brandViewing ? userDetails.brand_id : '', name: brandViewing ? userDetails.brand_name : '' }}
                                                            renderOption={(props, option) => {
                                                                return (
                                                                    <li {...props} key={option.brand_id}>{option.name}</li>
                                                                );
                                                            }}
                                                            sx={{ width: 300 }}
                                                            getOptionLabel={(option) => option.name}
                                                            renderInput={(params) => <TextField {...params} label="Select a Brand" />}
                                                            onChange={(event, value) => handleBrand(value)}
                                                        />
                                                    </div>
                                                </>
                                                : null
                                            }
                                        </div>
                                    </>)
                                    :
                                    (typeId === 1802 || typeId === 1806) &&
                                    <div className='search-bar-cont'>
                                        <Autocomplete
                                            style={{ width: '100%' }}
                                            disablePortal
                                            id="combo-box-demo"
                                            options={brands}
                                            defaultValue={{ brand_id: brandViewing ? userDetails.brand_id : '', name: brandViewing ? userDetails.brand_name : '' }}
                                            renderOption={(props, option) => {
                                                return (
                                                    <li {...props} key={option.brand_id}>{option.name}</li>
                                                );
                                            }}
                                            sx={{ width: 300 }}
                                            getOptionLabel={(option) => option.name}
                                            renderInput={(params) => <TextField {...params} label="Select a Brand" />}
                                            onChange={(event, value) => handleBrand(value)}
                                        />
                                    </div>
                                }


                                <div className='account-details'>
                                    <span className="user-profile-brand-name" id="brand-name">{!username ? brandName : username}</span>
                                    <span className="user-profile-text" id="user-email">{email}</span>
                                    {/* <div className="profile-container">
                                    <img src={ProfileIcon} width="100%" alt="" />
                                </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default Header;