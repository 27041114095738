import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from "@material-ui/core/styles";
import Path21Blue from '../../../assets/images/Path21 blue.png';
import "../../../assets/css/style.css";
import RejectModal from '../RejectModal/RejectModal';
import { approvePost, flagPost, getPosts } from '../../../store/actions/Dashboard/dashboardActions';
import UpdatePostStatusAPI from '../../../services/Dashboard/UpdatePostStatusAPI';
import GetPostsAPI from '../../../services/Dashboard/GetPostsAPI';
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/styles.css";
import { PRIMARY_COLOR } from '../../../utils/CssConstants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Notes from '../Notes/Notes';
import Helpers from '../../../utils/Helpers';
import SeeMore from './SeeMore';
import GetCampaignPreference from '../../../services/Campaign/CampaignList/GetCampaignPreference';
import { CircularProgress } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import Videopopup from './Videopopup';


const styles = {
    approveRejectCont: {
        position: 'absolute',
        zIndex: 10,
        marginTop: 15,
        right: 0
    },
    green: {
        color: 'rgba(55, 200, 151, 1)',
    },
    red: {
        color: 'rgba(200, 55, 104, 1)',
    },
    orange: {
        color: 'rgb(253, 87, 4)'
    },
    only: {
        borderBottom: 'none',
    },
    postImages: {
        borderRadius: 10,
        width: 180,
        height: 180,
        objectFit: 'cover',
        maxHeight: 'inherit',
        maxWidth: 'inherit'
    },
    seeMore: {
        fontWeight: 500,
        textDecoration: 'underline',
        cursor: 'pointer',
        width: 'fit-content',
        '&:hover': {
            color: 'rgba(0, 0, 0, 0.85)'
        },
        userSelect: 'none'
    },
    playIcon: {
        fontSize: '75px',
        position: 'absolute',
        color: 'darkgray',
        opacity: '0.6'
    }
};



const TableData = ({ classes, postsData, seriesIndex, currentPage, paginate, selectedFilter, toastSuccess, toastError, userDetails, brandView }) => {

    const [showApproveReject, setApproveReject] = useState(false);
    const [showRules, setShowRules] = useState(false);
    const [showRulesLoading, setShowRulesLoading] = useState({});
    const [currentSelection, setCurrentSelection] = useState(null);
    const [i, setI] = useState('');
    const [disableBtn, setDisableBtn] = useState(false);
    const [showModal, setModal] = useState(false);
    const [openlightBox, setOpenLightBox] = useState(false);
    const [images, setImages] = useState([]);
    const [openNotes, setOpenNotes] = useState(false);
    const [postDetails, setPostDetails] = useState({})
    const [campaignPreferenceData, setCampaignPreferenceData] = useState("")
    const [videoBoxOpen, setVideoBoxOpen] = React.useState({});

    // Update the state to open the popup for a specific video
    const openVideoPopup = (postId) => {
        setVideoBoxOpen(prevState => ({
            ...prevState,
            [postId]: true
        }));
    };

    // Update the state to close the popup for a specific video
    const closeVideoPopup = (postId) => {
        setVideoBoxOpen(prevState => ({
            ...prevState,
            [postId]: false
        }));
    };

    const userType = useSelector((state) => state.authReducer.userDetails.type_id);

    const node = useRef();
    const dispatch = useDispatch();

    const handleStatus = (index) => {
        setModal(false)
        setApproveReject(!showApproveReject)
    }

    const updateStatus = (postId, index, notes = null, status = 703) => {
        setDisableBtn(true);
        UpdatePostStatusAPI(postId, status, notes).then(data => {
            if (data.status === "SUCCESS") {
                dispatch(status === 710 ? flagPost(postId) : approvePost(postId));
                setTimeout(function () {
                    GetPostsAPI(currentPage, 10, selectedFilter, (brandView ? userDetails.brand_id : null)).then(data => {
                        if (index === 0 && postsData.length === 1) {
                            paginate(index, currentPage - 1)
                        }
                        dispatch(getPosts(data));
                        setDisableBtn(false);
                    }).catch(err => console.log(err))
                    setOpenNotes(false)
                    toast.success(data.message)
                }, 300);
            } else {
                setDisableBtn(false);
                toast.error(data.response.data.error);
            }
        });

        setApproveReject(!showApproveReject)
    }

    const handlePopup = (index, postId) => {
        setI(index)
        if (i === index) {
            setCurrentSelection(postId);
        }
        setApproveReject(!showApproveReject)
        setModal(true)
    }

    const handleNotes = (postId, index, notes, statusId) => {
        setOpenNotes(true)
        setPostDetails({ postId, index, notes, statusId })
    }

    const sendImages = (post, index) => {
        setImages([{ src: post.thumzup_image, alt: "Post content", title: "Post Content" }]);
        setOpenLightBox(true)
    }

    //Show Rules Fun
    const handleShowRules = (id, index) => {
        setShowRulesLoading(prevLoadingStates => ({
            ...prevLoadingStates,
            [index]: true
        }));

        GetCampaignPreference(id).then(data => {
            if (data.status === "SUCCESS") {
                setCampaignPreferenceData(data?.data)
                setShowRules(true)
                setShowRulesLoading(prevLoadingStates => ({
                    ...prevLoadingStates,
                    [index]: false
                }));

            }
            else {
                toast.error(data.response.data.error);
                setShowRules(false)
                setShowRulesLoading(prevLoadingStates => ({
                    ...prevLoadingStates,
                    [index]: false
                }));
            }

        }).catch(err => {
            setShowRulesLoading(prevLoadingStates => ({
                ...prevLoadingStates,
                [index]: false
            }));
        });
    }


    useEffect(() => {
        function handleClickOutside(event) {
            if (node.current && !node.current.contains(event.target)) {
                setApproveReject(false);
                setShowRules(false)
                setModal(false);
                setI('');
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [node]);



    return (
        <>
            {(postsData.length > 0) && postsData.map((posts, index) =>
                <div className="table-component" key={posts.id}>
                    <div className="row table-row">
                        <div className="col mt-5 small row-margins column-sizing" id="table-index">#{seriesIndex + index}</div>
                        <div className="col mt-5 medium row-margins column-sizing" id="table-user-info">
                            <span>{posts.phone_number}</span>
                            <span>
                                <a href={'https://www.instagram.com/' + posts.user_handles} target="_blank" rel="noreferrer" style={{ color: PRIMARY_COLOR }}>
                                    {posts.user_handles !== null ? '@' + posts.user_handles : ''}
                                </a>
                            </span>
                            <span>{posts?.follower_count && 'Followers ' + Helpers.convertNumbers(posts.follower_count)}</span>
                        </div>
                        <div className="col mt-3 large row-margins image-margins column-sizing" id="table-post-image">
                            {posts.content_type === "IMAGE" || posts.content_type === null ? (
                                <>
                                    <div className="position-relative d-flex justify-content-center align-items-center">
                                        <img className={classes.postImages} src={posts.thumzup_image} onClick={() => sendImages(posts, 0)} alt="" />
                                    </div>
                                    <a style={{ textAlign: 'center', width: 180, margin: '10px 0' }} href={posts.post_image} target='_blank' rel='noreferrer'>{posts?.post_image && 'Checkout Post'}</a>
                                </>
                            )
                                : posts.content_type === "VIDEO" ?
                                    (
                                        <>
                                            <div className="position-relative d-flex justify-content-center align-items-center" style={{ cursor: 'pointer' }}>
                                                <img className={classes.postImages} src={posts.thumbnail_url} onClick={() => openVideoPopup(posts.id)} alt="" />
                                                <FontAwesomeIcon icon={faPlay} className={classes.playIcon} color={'black'} id="play-icon" alt="" onClick={() => openVideoPopup(posts.id)} />
                                            </div>
                                            <a style={{ textAlign: 'center', width: 180, margin: '10px 0' }} href={posts.post_video} target='_blank' rel='noreferrer'>{posts?.post_video && 'Checkout Post'}</a>
                                        </>
                                    )
                                    : null
                            }
                            {videoBoxOpen[posts.id] &&
                                <Videopopup videoURL={posts.thumzup_video} videoBoxOpen={videoBoxOpen} handleClose={() => closeVideoPopup(posts.id)} />
                            }

                        </div>
                        {/* <div className="col mt-3 large row-margins image-margins column-sizing" id="table-insta-image">
                            <img className={classes.postImages} src={posts.thumzup_image} onClick={() => sendImages(posts, 1)} alt="" />
                        </div> */}
                        <div className="col mt-5 large row-margins column-sizing" id="table-post-caption">
                            <SeeMore caption={posts.caption} index={index} />
                        </div>
                        <div className="col mt-4 large row-margins column-sizing" id="table-post-brand-campaign"><span className="brand-text">{posts.brand}</span><span>{posts.campaign}</span></div>
                        <div className="col mt-5 small row-margins column-sizing" id="table-amount">${posts.amount}</div>
                        <div className="col mt-5 small-2 row-margins column-sizing" style={{ wordBreak: 'break-word' }} id="table-date">
                            <span>{Helpers.formatDate(posts.date + 'Z')}</span>
                        </div>
                        {selectedFilter === 705 && <div className="col mt-5 medium row-margins column-sizing" id="table-reject-reason"><span>{posts.rejected_reason && posts.rejected_reason}</span></div>}
                        {selectedFilter === 710 && <div className="col mt-5 medium row-margins column-sizing" id="table-reject-reason"><span>{posts.flagged_notes && posts.flagged_notes}</span></div>}

                        <div className="pending-box col mt-5  d-flex flex-column align-items-end medium row-margins text-end">
                            <div className='d-flex'>
                                {(posts.status === "Pending") && (userType === 1802 || userType === 1801 || userType === 1806) && (
                                    <>
                                        <div className={`${"pendi"} `} id="table-status">Show rules</div>
                                        {!showRulesLoading[index] ?
                                            <div className="pendiimage" onClick={() => { handleShowRules(posts?.campaign_id, index); setI(index); }}>
                                                <img src={Path21Blue} id="status-arrow" width="22px" alt="" />
                                            </div>
                                            : <CircularProgress size={18} style={{ marginLeft: '5px' }} />}
                                    </>
                                )}

                                {showRules && i === index && (
                                    <div ref={node} style={{ position: 'relative' }}>
                                        <div className={classes.approveRejectCont}>
                                            <div className='showrules'>
                                                <p>
                                                    Must have {campaignPreferenceData?.minimum_followers < 1 || campaignPreferenceData?.minimum_followers == undefined || campaignPreferenceData?.minimum_followers == null ? 0 : campaignPreferenceData?.minimum_followers} followers
                                                </p>
                                                <p>
                                                    Post must be live {campaignPreferenceData?.post_stay_days} {campaignPreferenceData?.post_stay_days > 1 ? 'days' : 'day'}
                                                </p>
                                                {campaignPreferenceData?.is_purchase_required && <p
                                                >
                                                    Purchase required - DM receipt to @thumz.up
                                                </p>}
                                                {campaignPreferenceData?.by_appointment_only && <p
                                                >
                                                    By appointment only
                                                </p>}
                                                {campaignPreferenceData?.is_post_photo_on_event_day &&
                                                    <p>
                                                        Photo must post on day of the event
                                                    </p>}
                                                {campaignPreferenceData?.post_limit &&
                                                    <p>
                                                        {campaignPreferenceData?.post_limit > 1 ? `${campaignPreferenceData?.post_limit} posts` : `${campaignPreferenceData?.post_limit} post`} per {' '}
                                                        {campaignPreferenceData?.time_period == 'Daily' ? campaignPreferenceData?.post_limit > 1 ? 'days' : 'day' : null}
                                                        {campaignPreferenceData?.time_period == 'Weekly' ? campaignPreferenceData?.post_limit > 1 ? 'Weeks' : 'Week' : null}
                                                        {campaignPreferenceData?.time_period == 'Monthly' ? campaignPreferenceData?.post_limit > 1 ? 'Months' : 'Month' : null}
                                                        {campaignPreferenceData?.time_period == 'Campaign' ? campaignPreferenceData?.post_limit > 1 ? 'Campaign' : 'Campaign' : null}
                                                    </p>}
                                                {campaignPreferenceData?.is_must_be21_or_older &&
                                                    <p>Must be 21 or older</p>}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='d-flex mt-1'>

                                <div
                                    style={
                                        userType === 1806 && posts.status === "Pending"
                                            ? { paddingRight: '26px' }
                                            : null
                                    }
                                    className={`${"pendi"} ${posts.status === "Approved" ? classes.green : posts.status === "Rejected" ? classes.red : posts.status === 'Flagged' ? classes.orange : ""} `} id="table-status">{posts.status === "PendingReview" ? "Pending" : posts.status}</div>
                                {((posts.status === "Pending") || (posts.status === "Flagged")) && userType !== 1806 && (
                                    <div className="pendiimage" onClick={() => { setI(index); handleStatus(); }}>
                                        <img src={Path21Blue} id="status-arrow" width="22px" alt="" />
                                    </div>
                                )}

                                {showApproveReject && i === index && (
                                    <div ref={node} style={{ position: 'relative' }}>
                                        <div className={classes.approveRejectCont}>
                                            <button
                                                id="app-rej"
                                                type="button"
                                                className="btn btn-secondary"
                                            >
                                                <span className="approve-btn d-flex">
                                                    {posts.status !== "Approved" &&
                                                        <button className={`${"approve"} ${posts.status === "Rejected" && classes.only}`} disabled={disableBtn} onClick={() => updateStatus(posts.id, index)} id="approve-txt">Approve</button>
                                                    }

                                                    {posts.status !== "Rejected" &&
                                                        <button className="reject" onClick={() => handlePopup(index, (posts.id))} id="reject-txt">Reject</button>
                                                    }
                                                    {posts.status !== "Flagged" &&
                                                        <button className="flagged" onClick={() => handleNotes(posts.id, index, posts.flagged_notes, 710)} id="flagged-txt">Flag</button>
                                                    }
                                                    {selectedFilter === 710 &&
                                                        <button className="flagged" onClick={() => handleNotes(posts.id, index, posts.flagged_notes, 710)} id="flagged-notes">Edit Notes</button>
                                                    }
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>

                            {showModal && i === index && (
                                <RejectModal showModal={showModal} currentPage={currentPage} paginate={paginate} closeModal={() => setModal(false)} totalPosts={postsData.length} postIndexinArray={i} index={currentSelection} setApproveReject={() => setApproveReject(false)} selectedFilter={selectedFilter} brandView={brandView} userDetails={userDetails} />
                            )}
                        </div>
                    </div>
                </div>
            )}
            {openlightBox && (
                <Lightbox
                    open={openlightBox}
                    close={() => setOpenLightBox(false)}
                    slides={images}
                    plugins={[Zoom, Captions]}
                />
            )}
            {openNotes && (
                <Notes
                    openNotes={openNotes}
                    setOpenNotes={setOpenNotes}
                    updateStatus={updateStatus}
                    postDetails={postDetails}
                />
            )}
            <ToastContainer theme='colored' />
        </>
    );
};

export default withStyles(styles)(TableData);