import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '../../../../Custom/Button';
import { WHITE_BG, FIELD_INCORRECT_DATA_VALIDATION } from '../../../../../utils/CssConstants';
import { useSelector, useDispatch } from 'react-redux';
import PostCreateCampaignAPI from '../../../../../services/Campaign/CreateCampaign/PostCreateCampaignAPI';
import PostUploadImageAPI from '../../../../../services/Campaign/CreateCampaign/PostUploadImageAPI';
import PostUploadCsvAPI from '../../../../../services/Campaign/CreateCampaign/PostUploadCsvAPI';
import GetConfirmPaymentAPI from '../../../../../services/Payment/GetConfirmPaymentAPI';
import CircularProgress from '@material-ui/core/CircularProgress';
import { resetCreateCampaign } from '../../../../../store/actions/Campaign/createCampaignActions';
import { useStripe } from "@stripe/react-stripe-js";
import Helpers from '../../../../../utils/Helpers';


const styles = theme => ({
    confirmationModalCont: {
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,    
        position: 'fixed',
        zIndex: 999,
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(196, 196, 196, 0.6)',
        display: 'flex'
    },
    confirmationModal: {
        background: WHITE_BG,
        boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
        borderRadius: 15,
        width: '50%',
        padding: 20,
        [theme.breakpoints.down('sm')]: {
            width: '90%'
        }
    },
    confirmationModalTitle: {
        marginTop: 10,
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 48,
        textAlign: 'center'
    },
    confirmationModalContent: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 20,
        textAlign: 'center',
    },
    confirmationModalBtns: {
        marginTop: 40,
        display: 'flex',
        justifyContent: 'space-around'
    },
    yesBtn: {
        background: "#1549d6",
        borderRadius: 10,
        color: WHITE_BG,
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 20,
        border: 'none',
        outline: 'none',
        width: '45%',
        height: 75
    },
    noBtn: {
        background: "#A82828",
        borderRadius: 10,
        color: WHITE_BG,
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 20,
        border: 'none',
        outline: 'none',
        width: '45%',
        height: 75  
    },
    fieldError: FIELD_INCORRECT_DATA_VALIDATION,
    btnLoading: {
        color: "#FFF !important"
    }
})

const ConfirmationModal = ({ classes, closeModal, showMessage, setActiveTab, setStateChanged }) => {

    const campaignDetails = useSelector((state) => state.createCampaignReducer.campaignDetails); 
    const billingDetails = useSelector((state) => state.createCampaignReducer.billingDetails);

    const [btnLoading, setBtnLoading] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false);
    const [csvResponse, setCSVResponse] = useState("");

    const dispatch = useDispatch();
    const stripe = useStripe();

    let defaultState = Helpers.returnDefaultState();

    const handleServerResponse = async (paymentIntentSecret, paymentIntentId) => {
        const result = await stripe.confirmCardPayment(paymentIntentSecret);
        handleStripeJsResult(result, paymentIntentId);
    }

    const handleStripeJsResult = async (result, paymentIntentId) => {
        if (result.error) {
            showMessage("The transaction was declined from the payment provider", "red");
        } else {
            const response = await GetConfirmPaymentAPI(paymentIntentId);
            if(response.data.stripe_payment_status !== "succeeded") {
                showMessage("Something went wrong, please try again", "red");
            } else {
                showMessage("Campaign is successfully created", "green");
                setTimeout(function() { 
                    // history.push('/')
                    setActiveTab(3);
                    dispatch(resetCreateCampaign(defaultState.initialBillingState, defaultState.initialCampaignState, defaultState.initialProgressState, defaultState.previousCards));
                }, 2000);
            }
        }
    }

    const submitCampaign = () => {
        setBtnLoading(true);
        setDisableBtn(true);
        setStateChanged(false);
            PostUploadImageAPI({ campaignDetails: campaignDetails }).then(data => {
                if(data.status === "SUCCESS") {
                    PostCreateCampaignAPI({ campaignDetails: campaignDetails, billingDetails: billingDetails, imageName: data.data.image_url }).then(createCampaignResponse => {
                            if(createCampaignResponse.status !== "SUCCESS") {
                                closeModal();
                                setDisableBtn(false);
                                if(createCampaignResponse.message) {
                                    showMessage(createCampaignResponse.response.data.error, "red");
                                }
                            } else {
                                if(campaignDetails.campaignType === "302" && campaignDetails.storeSelection === "Many") {
                                    PostUploadCsvAPI({ campaignDetails: campaignDetails, campaignId: createCampaignResponse.data.campaign_id }).then(data => {
                                        setBtnLoading(false);
                                        if(data.status !== "SUCCESS") {
                                            closeModal();
                                            setDisableBtn(false);
                                            if(data.message) {
                                                showMessage(data.response.data.error, "red");
                                            }
                                        } else {
                                            if(createCampaignResponse.data.stripe_payment_status !== "succeeded") {
                                                setBtnLoading(false);
                                                closeModal();
                                                if(billingDetails.couponDetails && (billingDetails.couponDetails.effective_coupon_amount < 0.50)) {
                                                    handleServerResponse(createCampaignResponse.data.payment_intent_client_secret, createCampaignResponse.data.payment_intent_id);
                                                }
                                            } else {
                                                closeModal();
                                                showMessage(createCampaignResponse.message, "green");
                                                setTimeout(function() { 
                                                    // history.push('/')
                                                    setActiveTab(3);
                                                    dispatch(resetCreateCampaign(defaultState.initialBillingState, defaultState.initialCampaignState, defaultState.initialProgressState, defaultState.previousCards));
                                                }, 2000);
                                            } 
                                            
                                        }
                                        
                                    });
                                } else {
                                    if(createCampaignResponse.data.stripe_payment_status !== "succeeded") {
                                        setBtnLoading(false);
                                        closeModal();
                                        if(billingDetails.couponDetails && (billingDetails.couponDetails.effective_coupon_amount < 0.50)) {
                                            handleServerResponse(createCampaignResponse.data.payment_intent_client_secret, createCampaignResponse.data.payment_intent_id);
                                        }
                                        handleServerResponse(createCampaignResponse.data.payment_intent_client_secret, createCampaignResponse.data.payment_intent_id);
                                    } else {
                                        setBtnLoading(false);
                                        closeModal();
                                        showMessage(createCampaignResponse.message, "green");
                                        setTimeout(function() { 
                                            // history.push('/')
                                            setActiveTab(3);
                                            dispatch(resetCreateCampaign(defaultState.initialBillingState, defaultState.initialCampaignState, defaultState.initialProgressState, defaultState.previousCards));
                                        }, 2000);
                                    }     
                                }   
                            }
                            
                    });
                } else {
                    closeModal();
                    setDisableBtn(false);
                    if(data.response) {
                        showMessage(data.response.data.error, "red");
                    }
                }
            });     
    }

    return (
        <div className={classes.confirmationModalCont} id="confirmationModal">
            <div className={classes.confirmationModal}>
                <div className={classes.confirmationModalTitle} id="confirmationTitle">Confirmation!</div>
                <div className={classes.confirmationModalContent} id="confirmationContent">Click Yes! to confirm your purchase order.</div>   
                <br />           

                {csvResponse !== "" &&
                    <div style={{ display: 'flex', flexDirection: 'column', padding: '0 20px' }}>
                        <div className={classes.confirmationModalContent} style={{ textAlign: 'left', fontWeight: 600 }} id="csv-rows-success">No of rows successful in csv upload: {csvResponse.data.success_rows} </div>   
                        <div className={classes.confirmationModalContent} style={{ textAlign: 'left', fontWeight: 600 }}
                        id="csv-rows-failed">No of rows failed in csv upload: {csvResponse.data.un_success_rows}</div>
                        <br />   

                        {csvResponse.data.un_success_rows > 0 &&
                            <div className={classes.confirmationModalContent} style={{ textAlign: 'left' }} id="csv-rows-message">Please check our sample file and follow exact format for all un-successful rows</div>   
                        }
                    </div>
                }
                <div className={classes.confirmationModalBtns}>
                    <Button className={classes.yesBtn} name={btnLoading ? <CircularProgress size={40} className={classes.btnLoading} /> : "Yes"} onClick={() => submitCampaign()} disabled={disableBtn} />
                    <Button className={classes.noBtn} name={"No"} onClick={() => closeModal()} />
                </div>
            </div>
        </div>
    )
}

export default withStyles(styles)(ConfirmationModal);