import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { PRIMARY_COLOR, WHITE_BG, FORM_GROUP, FORM_INPUT_CONTAINER_STYLES, FORM_INPUT_STYLES, CAMPAIGN_FORM_STEPS_HEADING, CAMPAIGN_FORM_STEPS_LABEL } from '../../../../utils/CssConstants';
import Button from '../../../Custom/Button';
import ImageUploader from '../ImageUploader/ImageUploader';
import Helpers from '../../../../utils/Helpers';
import ConfirmationModal from './ConfirmationModal/ConfirmationModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";


const styles = theme => ({
    previewTop: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    previewRight: {
        width: '60%',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    previewLeft: {
        width: '40%',
        display: 'flex',
        flexDirection: 'column',
        padding: '30px 0px 0px 40px',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    previewBottom: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    formGroup: FORM_GROUP,
    formInputContStyles: FORM_INPUT_CONTAINER_STYLES,
    formInputStyles: FORM_INPUT_STYLES,
    formHeadingStyles: CAMPAIGN_FORM_STEPS_HEADING,
    formInputLabels: CAMPAIGN_FORM_STEPS_LABEL,
    formInputLabelResponsive: {
        [theme.breakpoints.down('sm')]: {
            fontSize: 18
        }
    },
    formSubmit: {
        background: PRIMARY_COLOR,
        color: WHITE_BG,
        border: 'none',
        borderRadius: 10,
        height: 60,
        width: '20%',
        marginTop: 125,
        outline: 'none',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 18,
        [theme.breakpoints.down('sm')]: {
            width: '40%',
            fontSize: 16
        }
    },
    dullText: {
        color: '#B7B7B7',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 20
    },
    termsOfServiceContainer: {
        color: '#000000',
        width: '100%',
        height: 100,
        display: 'flex',
        padding: 10,
        fontSize: 18,
        fontStyle: 'normal',
        fontFamily: 'Poppins',
        fontWeight: 'normal',
        borderRadius: 15,
        marginBottom: 10,
        flexDirection: 'row',
        alignItems: 'baseline'
    },
    rememeberCheckbox: {
        height: 15,
        width: 15,
        marginRight: 10
    }
});

const Preview = ({ classes, step, setStep, prevStep, setReviewComplete, setActiveTab, setStateChanged }) => {

    const campaignDetails = useSelector((state) => state.createCampaignReducer.campaignDetails);
    const billingDetails = useSelector((state) => state.createCampaignReducer.billingDetails);
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHER_KEY);

    let cardNumberStripped = billingDetails.cardNumber.replace(/ /g, '');
    let cardNumber = cardNumberStripped.replace(/\d(?=\d{4})/g, "*");

    const [showModal, setModal] = useState(false);
    const [termsAndService, setTermsAndService] = useState(false);

    const showMessage = (message, prompt) => {
        if (prompt === "green") {
            toast.success(message);
        } else {
            toast.error(message);
        }
    }

    const returnCampaignCost = () => {
        if (billingDetails.couponDetails) {
            return Helpers.calculateBudgetWithStripeCharge(billingDetails.couponDetails.campaign_payable_amount)
        } else {
            return Helpers.calculateBudgetWithStripeCharge(campaignDetails.payoutAmount)
        }
    }
    return (
        <Elements stripe={stripePromise}>
            <div className={classes.previewTop}>
                <div className={classes.previewRight}>
                    <h3 className={classes.formHeadingStyles} id="section-heading">Review Campaign Details:</h3>
                    <div className={classes.formInputContStyles}>
                        <label htmlFor="campaign-name" className={`${classes.formInputLabels} ${classes.formInputLabelResponsive}`} style={{ width: '40%' }} id="campaign-name-label">Campaign Name</label>
                        <input className={classes.formInputStyles} id="campaign-name" type="text" value={campaignDetails.campaignName} readOnly={true} />
                    </div>
                    <div className={classes.formInputContStyles}>
                        <label htmlFor="campaign-type" className={`${classes.formInputLabels} ${classes.formInputLabelResponsive}`} style={{ width: '40%' }} id="campaign-type-label">Campaign Type</label>
                        <input className={classes.formInputStyles} id="campaign-type" type="text" value={campaignDetails.campaignType === "301" ? "Direct To Customer" : campaignDetails.campaignType === "302" ? "Brick and Mortar" : ""} readOnly={true} />
                    </div>
                    <div className={classes.formInputContStyles}>
                        <label htmlFor="payout-amount" className={`${classes.formInputLabels} ${classes.formInputLabelResponsive}`} style={{ width: '40%' }} id="payout-amount-label">Payout Amount</label>
                        <div className={classes.formInputStyles} id="payout-amount" type="text" readOnly={true}>{'$' + parseInt(campaignDetails.perPost).toFixed(2)} <span style={{ color: "#B7B7B7" }}>/post</span></div>
                    </div>
                    <div className={classes.formInputContStyles}>
                        <label htmlFor="no-of-posts" className={`${classes.formInputLabels} ${classes.formInputLabelResponsive}`} style={{ width: '40%' }} id="no-of-posts-label"># of Posts</label>
                        <input className={classes.formInputStyles} id="no-of-posts" type="text" value={campaignDetails.noOfPosts} readOnly={true} />
                    </div>
                    <div className={classes.formInputContStyles}>
                        <label htmlFor="campaign-cost" className={`${classes.formInputLabels} ${classes.formInputLabelResponsive}`} style={{ width: '40%' }} id="campaign-cost-label">Campaign Cost</label>
                        <input className={classes.formInputStyles} id="campaign-cost" type="text" value={'$' + Helpers.numberWithCommas(returnCampaignCost())} readOnly={true} />
                    </div>
                    <div className={classes.formInputContStyles}>
                        <label htmlFor="location" className={`${classes.formInputLabels} ${classes.formInputLabelResponsive}`} style={{ width: '40%' }} id="location-label">Location</label>
                        <input className={classes.formInputStyles} id="location" type="text" value={campaignDetails.location} readOnly={true} />
                    </div>
                    <div className={classes.formInputContStyles}>
                        <label htmlFor="network" className={`${classes.formInputLabels} ${classes.formInputLabelResponsive}`} style={{ width: '40%' }} id="network-label">Network</label>
                        <input className={classes.formInputStyles} id="network" type="text" value={campaignDetails.network} readOnly={true} />
                    </div>
                    {billingDetails.paymentMode === "newCard" &&
                        <div className={classes.formInputContStyles}>
                            <label htmlFor="billing-name" className={`${classes.formInputLabels} ${classes.formInputLabelResponsive}`} style={{ width: '40%' }} id="billing-name-label">Bill To</label>
                            <input className={classes.formInputStyles} id="billing-name" type="text" value={billingDetails.nameOnCard} readOnly={true} />
                        </div>
                    }
                    <div className={classes.formInputContStyles}>
                        <label htmlFor="billing-card" className={`${classes.formInputLabels} ${classes.formInputLabelResponsive}`} style={{ width: '40%' }} id="billing-card-label">Card Details</label>
                        {billingDetails.paymentMode === "previousCards" ?
                            <input className={classes.formInputStyles} id="billing-card" type="text" value={"**** **** **** " + billingDetails.selectedPreviousCard.last_4_character} readOnly={true} />
                            :
                            <input className={classes.formInputStyles} id="billing-card" type="text" value={cardNumber} readOnly={true} />
                        }
                    </div>

                    <div className='mt-5' style={{ marginLeft: '0' }}>
                        <h3 className={classes.formHeadingStyles}>Campaign Preference:</h3>

                        <div>
                            {campaignDetails?.minFollowers &&
                                <div className={classes.formInputContStyles}>
                                    <label htmlFor="billing-card" className={`${classes.formInputLabels} ${classes.formInputLabelResponsive}`} style={{ width: '100%' }}>A minimum of {campaignDetails?.minFollowers < 1 || campaignDetails?.minFollowers == undefined || campaignDetails?.minFollowers == null ? 0 : campaignDetails?.minFollowers} followers is required.</label>
                                </div>
                            }
                            {campaignDetails?.hiddenCampaign &&
                                <div className={classes.formInputContStyles}>
                                    <label htmlFor="billing-card" className={`${classes.formInputLabels} ${classes.formInputLabelResponsive}`} style={{ width: '100%' }}>The campaign is hidden.</label>
                                </div>
                            }
                            {campaignDetails?.purchaseRequired &&
                                <div className={classes.formInputContStyles}>
                                    <label htmlFor="billing-card" className={`${classes.formInputLabels} ${classes.formInputLabelResponsive}`} style={{ width: '100%' }}>Purchase Required.</label>
                                </div>
                            }
                            {campaignDetails?.postDuration &&
                                <div className={classes.formInputContStyles}>
                                    <label htmlFor="billing-card" className={`${classes.formInputLabels} ${classes.formInputLabelResponsive}`} style={{ width: '100%' }}>The post must stay up for {campaignDetails?.postDuration} {campaignDetails?.postDuration > 1 ? 'days' : 'day'}.</label>
                                </div>
                            }
                            {campaignDetails?.postEventDay &&
                                <div className={classes.formInputContStyles}>
                                    <label htmlFor="billing-card" className={`${classes.formInputLabels} ${classes.formInputLabelResponsive}`} style={{ width: '100%' }}>Photo must stay up the day of the event.</label>
                                </div>
                            }
                            {campaignDetails?.isMustBe21OrOlder &&
                                <div className={classes.formInputContStyles}>
                                    <label htmlFor="billing-card" className={`${classes.formInputLabels} ${classes.formInputLabelResponsive}`} style={{ width: '100%' }}>Must be 21 or older</label>
                                </div>
                            }

                        </div>
                    </div>



                    <div className={classes.termsOfServiceContainer}>
                        <input className={classes.rememeberCheckbox} type="checkbox" id="remember" value={termsAndService} onChange={e => setTermsAndService(!termsAndService)} />
                        <label htmlFor="remember" id="remember-label">I agree to the Thumzup <a href="https://www.thumzupmedia.com/advertiser-terms-of-service/" rel='noreferrer' target="_blank">Terms of Service</a> and <a href="https://www.thumzupmedia.com/privacy-policy/" rel='noreferrer' target="_blank">Privacy Policy</a></label>
                    </div>
                </div>

                <div className={classes.previewLeft}>
                    <ImageUploader image={campaignDetails.image} displayOnly={true} campaignName={campaignDetails.campaignName} />
                </div>
            </div>

            <div className={classes.previewBottom}>
                {step !== 1 && <Button className={classes.formSubmit} id="back-btn" name={"Back"} onClick={prevStep} />}
                <Button className={classes.formSubmit} style={{ backgroundColor: termsAndService ? PRIMARY_COLOR : '#c1c1c1' }} id="submit-btn" name={"Purchase Campaign"} onClick={() => setModal(true)} disabled={termsAndService ? false : true} />
            </div>
            {
                showModal && (
                    <ConfirmationModal showModal={showModal} closeModal={() => setModal(false)} showMessage={showMessage} setActiveTab={setActiveTab} setStateChanged={setStateChanged} />
                )
            }
            <ToastContainer theme='colored' />
        </Elements >
    )
}

export default withStyles(styles)(Preview);