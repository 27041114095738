import React, { Fragment } from 'react';
import { BrowserRouter, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Dashboard from '../components/Dashboard/Dashboard';
import Campaign from '../components/Campaign/Campaign';
import Users from '../components/Users/Users';
import PostHistory from '../components/PostHistory/PostHistory';
import Login from '../components/Auth/Login';
import BrandLogin from '../components/Auth/BrandLogin';
import BrandRegister from '../components/Auth/BrandRegister';
import SignupVerification from '../components/Auth/SignupVerification';
import SignupSuccess from '../components/Auth/SignupSuccess';
import GetStarted from '../components/Auth/GetStarted';
import InstagramSetup from '../components/Auth/InstagramSetup';
import ProtectedRoutes from './ProtectedRoutes';
import InvalidRoute from '../components/InvalidRoute/InvalidRoute';
import Helpers from '../utils/Helpers';
import ForgotPassword from '../components/Auth/ForgotPassword';
import PasswordReset from '../components/Auth/PasswordReset';
import Coupon from '../components/Coupon/Coupon';
import BrandSummary from '../components/BrandSummary/BrandSummary';
import AgencyLogin from '../components/Auth/AgencyLogin';

const AppRoutes = () => {

    const isAuth = useSelector((state) => state.authReducer.isAuthenticated);
    const userType = useSelector((state) => state.authReducer.userDetails.type_id);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let queryParams = new URLSearchParams(useLocation().search);



    return (
        <>
            <Routes>
                {/* Unauthenticated Routes */}
                <Route path="/" element={<BrandLogin />} />
                <Route path="/brand-login" element={<BrandLogin />} />
                <Route path="/agency-login" element={<AgencyLogin />} />
                <Route path="/brand-register" element={<BrandRegister />} />
                <Route path="/login" element={<Login />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/password_reset" element={<PasswordReset />} />

                {/* Authenticated Routes */}
                <Route isAuth={isAuth} path="/signup-verification" element={<SignupVerification />} />
                <Route isAuth={isAuth} path="/verify" element={<SignupSuccess />} />
                {isAuth &&
                    <Route isAuth={isAuth} path="/get-started" element={<GetStarted />} />
                }
                {isAuth &&
                    <Route isAuth={isAuth} path="/instagram-setup" element={<InstagramSetup />} />
                }

                <Route path='/dashboard' element={
                    <ProtectedRoutes isAuth={isAuth}>
                        <Dashboard />
                    </ProtectedRoutes>
                } />
                {isAuth && (userType === 1802 || userType === 1806) &&
                    <Route path='/coupon' element={
                        <ProtectedRoutes isAuth={isAuth}>
                            <Coupon />
                        </ProtectedRoutes>
                    } />
                }
                {/* {isAuth && userType !== 1801 && */}
                {userType !== 1805 && <Route path='/users' element={
                    <ProtectedRoutes isAuth={isAuth}>
                        <Users />
                    </ProtectedRoutes>
                } />}
                {/* } */}
                {/* {isAuth && userType !== 1801 && */}
                {userType !== 1805 &&
                    <Route path='post-history' element={
                        <ProtectedRoutes isAuth={isAuth}>
                            <PostHistory />
                        </ProtectedRoutes>}
                    />
                }
                {/* } */}
                {/* {(isAuth && (brandViewing || userType === 1801)) && */}
                {isAuth && userType !== 1805 &&
                    <Route path='campaigns' element={
                        <ProtectedRoutes isAuth={isAuth}>
                            <Campaign />
                        </ProtectedRoutes>
                    } />
                }

                {isAuth && (userType === 1802 || userType === 1801 || userType === 1806) &&
                    <Route path='/brand-summary' element={
                        <ProtectedRoutes isAuth={isAuth}>
                            <BrandSummary />
                        </ProtectedRoutes>
                    } />
                }

                {/* {isAuth && userType !== 1801 && 
                    <Redirect to="dashboard" push={true} />
                } */}
                {(isAuth === false && window.location.pathname !== '/brand-login' && queryParams.get('rechargeCampaign')) && navigate(`/brand-login?returnUrl=${window.location.pathname + window.location.search}`)}
                <Route element={<InvalidRoute />} />
            </Routes>
        </>
    );
}

export default AppRoutes;